import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../store";
import {
    useWeb3ModalAccount,
  } from "@web3modal/ethers5/react";


export default function Header({ selectedTab }) {
    const dispatch = useDispatch();
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const user = useSelector((state) => state.user);
    const email = user[0].email;

    const initial = email ? email.charAt(0).toUpperCase() : '';

    const [isDropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleDropdown = () => {
        setDropdown(!isDropdown);
    }

    const handleClickOutside = (event) => {
        if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdown]);

    const handleLogout = async () => {
        dispatch(setUser(""));
        window.location = '/';
    }

    return (
        <div className='fixed left-0 top-0 right-0 backdrop-blur-sm z-10'>
            <nav className='flex justify-between px-5 py-5 md:py-3'>
                <div className='w-[50%] flex gap-7'>
                    <div className='hidden md:block w-[30%]'>
                        <a href='/'><img src='/assets/MBC_logo.png' className='w-[60px] mx-auto'></img></a>
                    </div>
                    <div className='flex items-center'>
                        <p className='font-bold font-Artemus uppercase flex flex-col md:flex-row'><p className='flex'><p className='text-white/50 text-sm uppercase font-Artemus my-auto'>Dashboard</p><span className='px-2 font-bold'>/</span></p><p className='uppercase text-sm md:text-base font-bold'>{selectedTab}</p></p>
                    </div>
                </div>
                <div className='w-[20%] flex justify-end items-center md:pr-10'>

                    <div className='flex w-[75%]'>
                    <w3m-button />
                    </div>
                    <button className='flex' >

                        <div className='h-10 w-10 flex justify-center items-center font-bold text-xl border rounded-full font-poppins'>{initial}</div>
                        <div className='flex items-center h-10 pl-2'><button className='flex items-center'>{email}
                        </button></div>
                    </button>
                </div>
                {/* {isDropdown && (
                    <div className='absolute font-poppins font-medium top-20 right-10 text-sm grid gap-2 w-[10rem] rounded-md px-3 py-2 border border-white/50 bg-[#571226]'
                        data-aos='fade-up' data-aos-duration='1000'
                        ref={dropdownRef}>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                )} */}
            </nav>
        </div>
    )
}