import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Layout/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AOS from "aos";
import axios from 'axios';
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { useDispatch } from "react-redux";
import { setUser, SET_TOKEN } from "../../store";
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { SERVER_URL } from '../../config';
import UAParser from 'ua-parser-js';
AOS.init();

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ username: "", password: "" });

  const handlePasswordToggle = () => {
    setVisible(!visible);
  };

  const validateInputs = () => {
    let valid = true;
    let tempErrors = { username: "", password: "" };

    if (!username) {
      tempErrors.username = "Username is required";
      valid = false;
    }

    if (!password) {
      tempErrors.password = "Password is required";
      valid = false;
    }

    setErrors(tempErrors);
    return valid;
  };

  const LoginUser = async () => {
    if (validateInputs()) {
      try {
        const response = await axios.post(`${SERVER_URL}/api/LoginUser`, { username, password });
        if (response.data.status === true) {
          toast.success('Welcome to MBC');
          const user = response.data.user

          await updateUserDevice(response.data.user[0].CusID);
          setTimeout(() => {
            dispatch(setUser(user));
            navigate('/');
          }, 2000);
        } else {
          if (response.data.message === 'User not found') {
            toast.error('User not found. Please sign up.');
            setTimeout(() => {
              navigate('/Signup');
            }, 3000);
          } else if (response.data.message === 'Password incorrect') {
            toast.error('Password incorrect. Please try again.');
          }
        }
      } catch (error) {
        console.error('Error during login:', error);
        toast.error('An error occurred. Please try again later.');
      }
    } else {
      console.log('Validation Failed');
    }
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '816161820702660',
        cookie: true,
        xfbml: true,
        version: 'v13.0'
      });
      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);


  const handleFacebookLogin = () => {
    if (window.FB) {
      window.FB.login(response => {
        if (response.authResponse) {
          window.FB.api('/me', { fields: 'name, email' }, userInfo => {
            axios.post(`${SERVER_URL}/api/login/facebook`, {
              facebookID: response.authResponse.userID,
              email: userInfo.email
            })
              .then(res => {
                if (res.data.success) {
                  navigate('/');
                }
              })
              .catch(err => {
                toast.error('User not found, please sign up first.');
                navigate('/Signup');
                console.log("Error during Facebook login:", err);
              });
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      }, { scope: 'email' });
    } else {
      console.error("Facebook SDK is not loaded.");
    }
  };


  const [isGoogleRegister, setIsGoogleRegister] = useState(true);
  const [googleFormData, setGoogleFormData] = useState({
    firstName: "",
    email: "",
    googleId: "",
  });

  const clientId = "763708000678-hdpn5ipmfl6fqssocnqsmspaem85muuk.apps.googleusercontent.com";

  const handleGoogleLogin = () => {
    toast.error("Action Can't be dont at the Moment")
    return false
    console.log("SignUp with Google button clicked");
    setIsGoogleRegister(true);
    console.log(isGoogleRegister);
  };

  const onSuccess = async (res) => {
    try {
      console.log("isGoogleRegister onSuccess", isGoogleRegister);

      if (isGoogleRegister) {


        const { googleId, email, givenName } = res.profileObj;

        const formData = {
          firstName: givenName,
          email,
          googleId,
        };
        setGoogleFormData(formData);
        console.log("GoogleFormData", googleFormData);

        const response = await axios.post(`${SERVER_URL}/api/GoogleEmail_Registercheck_User`, { email });

        if (response.data.message === "exists") {

          // console.log("token",response)
          toast.success("Login successful!")
          setTimeout(() => {

            navigate("/");
            dispatch(setUser(email));
            dispatch(SET_TOKEN(response.data.token));


          }, 20000);
        } else if (response.data.message === "not_exists") {
          toast.error("Email not existed , please SignUP")
          navigate('/Signup');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFailure = (error) => {
    console.error("Google sign-up failed:", error);

    setIsGoogleRegister(false);
  };

  useEffect(() => {
    const initGoogleClient = async () => {
      try {
        await gapi.client.init({
          clientId: clientId,
          scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
        });
      } catch (error) {
        console.error("Error initializing Google API client:", error);
      }
    };

    gapi.load("client:auth2", initGoogleClient);
  }, [clientId]);





  const [ipAddress, setIpAddress] = useState('');
  const [ipCountryName, setIpCountryName] = useState('');


  const getOrCreateDeviceId = () => {
    let deviceId = localStorage.getItem('MBC_deviceId');

    if (!deviceId) {
      deviceId = 'device-' + Math.random().toString(36).substr(2, 9);
      localStorage.setItem('MBC_deviceId', deviceId);
    }
    return deviceId;
  };

  const getDeviceInfo = () => {
    const parser = new UAParser();
    const result = parser.getResult();

    const deviceInfo = {
      browser: result.browser.name,
      browserVersion: result.browser.version,
      os: result.os.name,
      osVersion: result.os.version,
      deviceType: result.device.type || 'desktop',
    };
    return deviceInfo;
  };

  const fetchIpAddress = async () => {
    try {
      const response = await axios.get('https://geolocation-db.com/json/');
      setIpAddress(response.data.IPv4);
      setIpCountryName(response.data.country_name)
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  useEffect(() => {
    fetchIpAddress();
  }, []);

  const updateUserDevice = async (user_id) => {
    const deviceId = getOrCreateDeviceId();
    const deviceInfo = getDeviceInfo();

    await fetchIpAddress();

    if (ipAddress) {
      try {
        const response = await axios.post(`${SERVER_URL}/api/track-users-devices`, {
          deviceId,
          user_id,
          deviceInfo,
          ipAddress,
          ipCountryName
        });
      } catch (error) {
        console.error('Error tracking device:', error);
      }
    } else {
      console.error('IP address is not available.');
    }
  };



  return (
    <div>
      <Header />
      <Toaster />

      <div
        className="sec-1 bg-black bg-cover min-h-screen font-poppins text-white pt-24 overflow-hidden"
        style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}
      >
        <div className='w-[90%] mx-auto flex justify-center items-center py-10'>
          <div className='flex lg:flex-row flex-col justify-center'>
            <div className='lg:w-[60%] w-full' data-aos="fade-right" data-aos-duration="2000">
              <div>
                <h1 className='md:text-6xl text-4xl font-Artemus font-bold uppercase text-shadow-custom'>My business club</h1>
                <p className='font-bold md:text-lg text-sm mt-2'>Welcome to private membership “Business Club”</p>
              </div>
              <div className='mt-10 w-full'>
                <img src='assets/Rectangle_39637.png' className='lg:w-10/12 ' />
                <div className='flex justify-end'>
                  <div className="bg-white/60 sm:py-7 py-4 sm:px-5 px-2 rounded-2xl border sm:w-32 w-24 lg:-translate-x-20 md:-translate-y-24 -translate-y-10 backdrop-blur-md">
                    <img src="../assets/Icon (2).png" />
                  </div>
                </div>
              </div>
            </div>

            <div className='lg:w-[40%]' data-aos="fade-left" data-aos-duration="2000">
              <div className='border-2 rounded-md py-5 md:px-10 px-3'>
                <div>
                  <p className='sm:text-6xl text-4xl font-bold'>LOGIN</p>
                  <p className='font-bold mt-1 text-sm px-2'>Sign in with email address</p>
                </div>
                <div className='border-b-2 py-7'>
                  <div className='mt-2 relative'>
                    <input
                      type='text'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.username && "border border-red-500"}`}
                      placeholder='Enter your UserID or Username'
                    />
                    <FontAwesomeIcon icon={faUser} className='text-[#A4A4A4] absolute left-3 top-3.5' />
                  </div>
                  {errors.username && <p className="px-2 text-xs mt-1">{errors.username}</p>}


                  <div className='mt-3 relative'>
                    <input
                      type={visible ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={`w-full rounded-lg focus:outline-none py-3 px-10 text-[#A4A4A4] text-sm ${errors.password && "border border-red-500"}`}
                      placeholder='Enter the password'
                    />
                    <FontAwesomeIcon icon={faLock} className='text-[#A4A4A4] absolute left-3 top-3.5' />
                    <button
                      onClick={handlePasswordToggle}
                      className="absolute top-0 bottom-0 right-0 flex justify-center items-center h-[100%] w-[40px]"
                    >
                      {visible ? (
                        <FontAwesomeIcon icon={faEye} size="sm" className="text-gray-400" />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} size="sm" className="text-gray-400" />
                      )}
                    </button>
                  </div>
                  {errors.password && <p className="px-2 text-xs mt-1">{errors.password}</p>}


                  <div className="mt-5 cursor-pointer" onClick={LoginUser}>
                    <div className='shadow-lg shadow-black/30 bg-white py-1 px-2 rounded-xl'>
                      <div className="w-full rounded-xl py-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-bold text-center">Sign in</div>
                    </div>
                  </div>
                </div>

                <div className='mt-5'>
                  {/* <p className='font-semibold text-sm'>Or continue with</p>
                  <div className='flex justify-between gap-2 mt-2 mb-5'>
                    <div className='bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg'>
                      <GoogleLogin
                        clientId={clientId}
                        buttonText="Login with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={false}
                        prompt="select_account"
                        redirectUri="http://localhost:3000/auth/callback"
                        render={(renderProps) => (
                          <button
                            onClick={() => {
                              handleGoogleLogin();
                              renderProps.onClick();
                            }}
                            disabled={renderProps.disabled}
                            className="flex items-center gap-2"
                          >
                            <img src="../assets/google.png" className="w-4 h-4" alt="Google Icon" />
                            <p className="font-semibold text-black text-sm">Google</p>
                          </button>
                        )}
                      />
                    </div>
                    <div className='bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg cursor-pointer' onClick={handleFacebookLogin}>
                      <img src='../assets/fb.png' className='w-5' />
                      <p className='font-semibold text-black text-sm'>Facebook</p>
                    </div>
                  </div>  */}
                  <p className='text-xs font-semibold text-center'> By registering you agree to our <span className='text-black font-semibold'> Terms and Conditions.</span></p>
                  <p className='text-center text-sm font-semibold mt-2'>Not a member ? <a href='/Signup' className='text-blue-800'>Signup</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
