import React from 'react';
import Chart from 'react-apexcharts';

const DepositWallet = () => {
    const depositChartOptions = {
        series: [50, 7.98, 42.03], // Example data
        chart: {
            type: 'donut',
        },
        labels: ['Credit', 'Debit', 'Balance'],
        colors: ['#9B59B6', '#BDC3C7', '#E74C3C'], // Unique colors
        legend: {
            show: false, // Disable legend
        },
        dataLabels: {
            enabled: true, // Enable data labels
            dropShadow: {
                enabled: false, // No drop shadows
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '65%', // Adjust donut size
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    return (
        <div className="w-full flex flex-col text-sm items-center">
            <div className="w-full">
                <div className="">
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <th className="py-2">Wallet Details</th>
                                <th className="py-2">Type</th>
                                <th className="py-2">Total (%)</th>
                                <th className="py-2">Amount ($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='py-2' rowSpan="3">
                                    <Chart 
                                        options={depositChartOptions} 
                                        series={depositChartOptions.series} 
                                        type="donut" 
                                        width="200" 
                                        height="200" 
                                    />
                                </td>
                                <td className="py-2">
                                    <span className="inline-block w-3 h-3 bg-[#9B59B6] mr-2"></span> Credit
                                </td>
                                <td className="py-2">50%</td>
                                <td className="py-2">$2840.4</td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    <span className="inline-block w-3 h-3 bg-[#BDC3C7] mr-2"></span> Debit
                                </td>
                                <td className="py-2">7.98%</td>
                                <td className="py-2">$453</td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    <span className="inline-block w-3 h-3 bg-[#E74C3C] mr-2"></span> Balance
                                </td>
                                <td className="py-2">42.03%</td>
                                <td className="py-2">$2387.4</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default DepositWallet;
