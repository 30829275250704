import React from 'react';
import Chart from 'react-apexcharts';

const IncomeWallet = () => {
    const incomeChartOptions = {
        series: [60, 15, 25], // Example data
        chart: {
            type: 'donut',
        },
        labels: ['Income', 'Expenses', 'Savings'],
        colors: ['#28B76B', '#F4D03F', '#1E90FF'], // Unique colors
        legend: {
            show: false, // Disable legend
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    return (
        <div className="w-full flex flex-col text-sm items-center">
            <div className="w-full">
                <div className="">
                    <table className="w-full text-left">
                        <thead>
                            <tr>
                                <th className="py-2">Wallet Details</th>
                                <th className="py-2">Type</th>
                                <th className="py-2">Total (%)</th>
                                <th className="py-2">Amount ($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='py-2' rowSpan="3">
                                    <Chart 
                                        options={incomeChartOptions} 
                                        series={incomeChartOptions.series} 
                                        type="donut" 
                                        width="200" 
                                        height="200" 
                                    />
                                </td>
                                <td className="py-2">
                                    <span className="inline-block w-3 h-3 bg-[#28B76B] mr-2"></span> Income
                                </td>
                                <td className="py-2">60%</td>
                                <td className="py-2">$6000</td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    <span className="inline-block w-3 h-3 bg-[#F4D03F] mr-2"></span> Expenses
                                </td>
                                <td className="py-2">15%</td>
                                <td className="py-2">$1500</td>
                            </tr>
                            <tr>
                                <td className="py-2">
                                    <span className="inline-block w-3 h-3 bg-[#1E90FF] mr-2"></span> Savings
                                </td>
                                <td className="py-2">25%</td>
                                <td className="py-2">$2500</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default IncomeWallet;
