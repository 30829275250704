import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';

export default function Tickethistory() {
    const tableEntries = [
        {
            id: "1",
            Txno: "9909237",
            custId: "126805",
            time: "8/16/2024 2:38:52 PM",
            message: "47246 Userid : 126805<br> Deposit Date :8/16/2024 12:00:00 AM<br> Amount USD :33.16<br> Amount BTC :33.16<br> Remark_all :Hello, more than 6 hours ago sent 33,16 usdt ex20 to wallet TUgto9SUQhJTmskuFQjFu4W9eV4jR1VF3L money still can''t see in my cabinet, why? ( Txn Key : dfd9eaa3bf93ea264706991a91343df6916f5eeee6acfa93a9704f69789a0a71 )",
            status: "Close",
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle dropdown change for entities per page
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };


    // Calculate pagination details
    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const [expandedRows, setExpandedRows] = useState([]);

    const toggleRowDetails = (id) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(id)
                ? prevExpandedRows.filter((rowId) => rowId !== id)
                : [...prevExpandedRows, id]
        );
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>Ticket History
                </h1>
                <div className=' rounded-lg mt-2 border '>
                    <div className='px-5 py-3 border-b  font-bold text-lg'>
                        <p>Search Ticket No</p>
                    </div>
                    <div className='flex flex-col gap-3 w-[100%] lg:w-[80%] mx-auto px-5 py-3'>
                        <div className='flex flex-col md:flex-row gap-3 items-center'>
                            <p className='text-end font-bold md:w-[20%]'>Cust ID</p>
                            <input className='w-[100%] md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'></input>
                        </div>
                        <div className='flex flex-col md:flex-row gap-3 items-center'>
                            <p className='text-end font-bold md:w-[20%]'>Enter Ticket No</p>
                            <input className='w-[100%] md:w-[50%] py-1 border outline-none px-3 rounded-md bg-transparent'></input>
                        </div>
                        <div className='flex justify-center'>
                            <button className='px-5 py-2 text-sm font-bold bg-gradient-to-br from-[#F23985] from-40% via-[#FF99BD] to-[#F23985] to-80% rounded-full shadow-md' >Search History</button>
                        </div>
                    </div>
                </div>
                <div className=' rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b'>
                        <div>
                            <p className='font-bold'>Showing All Ticket</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='border-b p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                        <div className=' text-sm font-bold m-auto'>
                            Total Record : 4
                        </div>
                        <div></div>
                        <div></div>
                        <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
                            <div className='w-[25%] md:w-[100%]'>
                                <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                            </div>
                        </div>
                        <div className=' text-sm font-bold m-auto'>
                            Page of 1 of 0
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='text-sm uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2 w-14 md:w-12'></th>
                                    <th className='px-2'>TxNo</th>
                                    <th className='px-2'>Cust_ID</th>
                                    <th className='px-2'>Time Stamp</th>
                                    <th className='px-2'>Send By Message</th>
                                    <th className='px-2'>Status</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <React.Fragment key={index}>
                                        <tr className='h-16 text-sm text-center rounded-md font-bold'>
                                            <td className='px-5 md:px-2'>{entity.id}</td>
                                            <td className=' md:px-2'>
                                                <img
                                                    src={expandedRows.includes(entity.id) ? '/assets/minus.png' : '/assets/plus.png'}
                                                    className='w-14 md:w-auto cursor-pointer'
                                                    onClick={() => toggleRowDetails(entity.id)}
                                                    alt={expandedRows.includes(entity.id) ? 'Minus Icon' : 'Plus Icon'}
                                                />
                                            </td>
                                            <td className='px-5 md:px-2'>{entity.Txno}</td>
                                            <td className='px-5 md:px-2'>{entity.custId}</td>
                                            <td className='px-5 md:px-2'>{entity.time}</td>
                                            <td className='px-5 md:px-2'>{entity.message}</td>
                                            <td className='px-5 md:px-2'>{entity.status}</td>
                                        </tr>
                                        {expandedRows.includes(entity.id) && (
                                            <tr className='text-sm '>
                                                <td colSpan="7">
                                                    <table className='w-full border'>
                                                        <thead>
                                                            <tr className='divide-x border-b'>
                                                                <th className='py-3 px-2'>Tx No</th>
                                                                <th className='py-3 px-2'>Receiver</th>
                                                                <th className='py-3 px-2'>Message</th>
                                                                <th className='py-3 px-2'>Date / Time</th>
                                                                <th className='py-3 px-2'>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='divide-x'>
                                                                <td className='px-5 md:px-2'>{entity.Txno}</td>
                                                                <td className='px-5 md:px-2'>{entity.custId}</td>
                                                                <td className='px-5 md:px-2'>Reply from Customer Care: Thanks for detail the amount is credited to your deposit wallet</td>
                                                                <td className='px-5 md:px-2'>8/19/2024 7:07:50 PM</td>
                                                                <td className='px-5 md:px-2'>{entity.status}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
