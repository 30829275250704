import React, { useEffect, useState, useRef } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCircle, faCirclePlay, faLongArrowDown, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';
import { faDiscord, faFacebook, faInstagram, faLinkedin, faTelegram, faWhatsapp, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { NavLink } from 'react-router-dom';
import '../Style/App.css';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { SERVER_URL } from "../../config";
import '../Style/App.css'
import Aos from 'aos';
import 'aos/dist/aos.css';

Aos.init();

export default function Home() {

    const [data, setData] = useState([]);
    const exploreSectionRef = useRef(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const cost = 50;
                const response = await axios.get(`${SERVER_URL}/api/GetTopIncomeAchievers`);
                const result = response.data;
                if (result.Statuscode === "200" && result.result === "SUCCESS") {

                    const updatedData = result.Data.map(item => {
                        const revenue = item.TotalIncome;
                        const profit = revenue - cost;
                        const netProfitMargin = ((profit / revenue) * 100).toFixed(2);
                        return { ...item, netProfitMargin };
                    });

                    const sortedData = updatedData.sort((a, b) => b.TotalIncome - a.TotalIncome);
                    setData(sortedData);

                    // console.log("sortedData",sortedData)
                } else {
                    throw new Error(result.Message);
                }
            } catch (error) {
                console.error(error.message);
            }
        };

        fetchData();
    }, []);


    // newsletter subscription

    const [email, setEmails] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // console.log("emails", email)
    const [videoplay, setvideoplay] = useState(false);
    const handleVideoplay = () => {
        setvideoplay(!videoplay)
    }
    useEffect(() => {
        if (videoplay) {
            // Disable scrolling when the popup is open
            document.body.style.overflow = 'hidden';
        } else {
            // Re-enable scrolling when the popup is closed
            document.body.style.overflow = 'auto';
        }

        // Clean up the effect when the component is unmounted
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [videoplay]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const loadingToastId = toast.loading('Subscribing...');
    
        try {
            const response = await axios.post(`${SERVER_URL}/api/newsletter_subscribe`,{
                email: email,
            });
    
            const { new_members, errors } = response.data;
    
            toast.dismiss(loadingToastId);
    
            if (new_members.length > 0) {
                // Successfully subscribed
                setStatusMessage('Email successfully subscribed!');
                setErrorMessage('');
                toast.success('Email successfully subscribed!');
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } else if (errors.length > 0) {
           
                console.log(errors)
                const errorMessage =  errors[0].error_code === "ERROR_CONTACT_EXISTS" ? 'Already Subscribed': 'Failed to subscribe'
                setStatusMessage('');
                setErrorMessage(errorMessage);
                toast.error(errorMessage);
            }
        } catch (error) {
            toast.dismiss(loadingToastId);
            setStatusMessage('');
            setErrorMessage('Failed to subscribe email.');
            toast.error('Failed to subscribe email.');
        }
    };


    return (
        <div className='text-white font-poppins w-[100%]'>
            <div className='fixed inset-0 z-0'>
                <img src='/assets/NewMBCbg.png' className='h-full w-full object-cover object-top'></img>
            </div>
            <div className='relative z-10 overflow-hidden'>
                <Header />
                <Toaster />
                <div className='sec-1 pt-28 pb-10 overflow-hidden '>
                    <div className='w-[85%] mx-auto flex flex-col md:flex-row gap-10'>
                        <div className='md:w-[55%] 2xl:pt-14'>
                            <div className='flex gap-2'>
                                <p className='flex gap-2 px-3 py-1 rounded-full border bg-white/25' data-aos="fade-right"><FontAwesomeIcon icon={faCircle} className='text-[5px] text-[#F98736] my-auto' />Ai</p>
                                <p className='flex gap-2 px-3 py-1 rounded-full border bg-white/25' data-aos="fade-right" data-aos-duration="1000"><FontAwesomeIcon icon={faCircle} className='text-[5px] text-[#F98736] my-auto' />Blockchain</p>
                            </div>
                            <p className='font-bold font-Artemus text-3xl md:text-6xl uppercase text-shadow-custom mt-5 text-center md:text-start' data-aos="fade-right" data-aos-duration="1000">MY BUSINESS</p>
                            <div className='flex gap-3'>
                                <img src='assets/people.png' className='my-auto' data-aos="fade-right" data-aos-duration="1500"></img>
                                <p className='font-bold font-Artemus text-3xl md:text-6xl uppercase text-shadow-custom my-auto' data-aos="fade-right" data-aos-duration="2000">club</p>
                            </div>
                            <p className='mt-5' data-aos="zoom-in" data-aos-duration="1000">At My Business Club, we empower entrepreneurs through a community-centric model that leverages education, blockchain technology, and an innovative referral program.</p>
                            <div className='flex mt-10'>
                                <div className='w-[50%] text-center'>
                                    <p className='font-bold font-Artemus text-3xl md:text-5xl uppercase '><CountUp end={10} duration={2.5} separator=',' /><span className='md:text-4xl my-auto'>+</span></p>
                                    <p className='text-lg font-bold' data-aos="fade-up" data-aos-duration="1000">Years Of Business Expertise </p>
                                </div>
                                <div className='w-[50%] text-center'>
                                    <p className='font-bold font-Artemus text-3xl md:text-5xl uppercase '><CountUp end={2675} duration={2.5} separator=',' /></p>
                                    <p className='text-lg font-bold' data-aos="fade-up" data-aos-duration="1000">On Boarded Community Users </p>
                                </div>
                            </div>
                            <div className=' md:mt-6 font-semibold'>
                                <p>Follow us on:</p>
                                <div className='flex gap-3 text-shadow-custom mt-3 text-2xl'>
                                    <a target='blank' href='https://www.facebook.com/profile.php?id=61559105265026'><FontAwesomeIcon icon={faFacebook} className='cursor-pointer' data-aos="fade-up" data-aos-duration="1000" /></a>
                                    <a target='blank' href='https://rb.gy/a1nli1'><FontAwesomeIcon icon={faXTwitter} className='cursor-pointer' data-aos="fade-up" data-aos-duration="1250" /></a>
                                    <a target='blank' href='https://www.instagram.com/mybusinessclub_official/'><FontAwesomeIcon icon={faInstagram} className='cursor-pointer' data-aos="fade-up" data-aos-duration="1500" /></a>
                                    <a target='blank' href='https://rb.gy/7x2p32'><FontAwesomeIcon icon={faLinkedin} className='cursor-pointer' data-aos="fade-up" data-aos-duration="1750" /></a>
                                </div>
                            </div>
                        </div>
                        <div className='md:w-[45%]  relative'>
                            <div className='flex'>
                                <div className='w-[80%]' data-aos="fade-left" >
                                    <img src='/assets/model.png'></img>
                                    <div><p className='mt-3 text-center pl-5'>Your Gateway To Entrepreneurial success</p></div>
                                </div>
                                <div className='w-[20%]' data-aos="fade-left" data-aos-duration="1000">
                                    {/*<div className='flex gap-3 absolute w-[7rem] lg:w-[11rem] 2xl:w-[13rem]  -translate-x-[40px] lg:-translate-x-[60px] translate-y-5 lg:translate-y-8 cursor-pointer' onClick={handleVideoplay}>
                                        <FontAwesomeIcon icon={faCirclePlay} className='text-4xl lg:text-6xl 2xl:text-[70px]' />
                                        <div className='grid content-center'>
                                            <p className='my-auto font-bold'>Watch demo</p>
                                            <p className='text-white/70 text-sm'>1:52 Min</p>
                                        </div>
                                    </div>*/}
                                    <div className='flex flex-col gap-1'>
                                        <NavLink target='_blank' to="https://chat.whatsapp.com/J5TXU53NYoh9U2nrC4IEia" >
                                            <div className='bg-white/15 p-5 md:p-7 flex justify-center rounded-xl mt-28'>
                                                <button className='text-3xl px-2 py-1 bg-[#3ED944] rounded-md'><FontAwesomeIcon icon={faWhatsapp} /></button>
                                            </div>
                                        </NavLink>
                                        <NavLink target='_blank' to="https://t.me/userguidembcbot" >
                                            <div className='bg-white/15 py-7 flex justify-center rounded-xl'>
                                                <button className=' text-3xl px-2 py-1 bg-blue-500 rounded-md'><FontAwesomeIcon icon={faTelegram} /></button>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-end mt-3'>
                                <div>
                                    <p className='text-center text-3xl fa-bounce'><FontAwesomeIcon icon={faLongArrowDown} /></p>
                                    <p>Scroll to Explore</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {videoplay && (
                    <div className='z-100 fixed inset-0 model-overlay flex items-center justify-center backdrop-blur-md bg-black/20 h-screen'>
                        <div className='md:w-[50%]' data-aos="fadeIn" data-aos-duration="2000">
                            <div className='flex justify-end'>
                                <FontAwesomeIcon className='cursor-pointer pb-3 pl-3' onClick={handleVideoplay} icon={faXmarkCircle} />
                            </div>
                            <iframe
                                width="100%"
                                height="500"
                                src="https://www.youtube.com/embed/OXWnxQOsZUU?autoplay=1&controls=0&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ borderRadius: '15px', overflow: 'hidden' }}
                            ></iframe>
                        </div>
                    </div>
                )}


                <div className='sec-2 md:pb-10 overflow-hidden'>
                    <div className='flex flex-col md:flex-row justify-between w-[85%] mx-auto'>
                        <div className='md:w-[55%]'>
                            <img src='/assets/MyBc.png' className='fade-in-image'></img>
                        </div>
                        <div className='md:w-[40%] grid content-center 2xl:gap-5' data-aos='fade-up' data-aos-duration="1500">
                            <p className='font-bold font-Artemus text-3xl md:text-6xl lg:-translate-x-[110px] uppercase text-shadow-custom mt-5'>Our Vision</p>
                            <p className='font-bold mt-5 lg:mt-10 text-xl' data-aos='zoom-in' data-aos-duration="1250">Elevating Entrepreneurs Through Collaboration</p>
                            <p className='my-3 md:my-7' data-aos='zoom-in' data-aos-duration="1500">Imagine a place where small businesses collaborate to achieve big deals, accessing opportunities often reserved for large institutions. </p>
                            <p className='md:mb-7 2xl:mb-10' data-aos='zoom-in' data-aos-duration="1750">Community is everything; no business can succeed without customers. The bigger the community, the more profits we can make together.</p>
                        </div>
                    </div>
                </div>
                <div className='sec-3 py-10 relative md:flex items-center justify-center'>
                    <div className='w-[90%] md:w-[85%] mx-auto z-0'>
                        <div className='md:w-[55%] lg:w-[40%]'>
                            <p className='font-bold text-xl' data-aos="fade-right" data-aos-duration="1000">Thriving Community of Entrepreneurs</p>
                            <p className='mt-7' data-aos='zoom-in' data-aos-duration="1250">We put people first. While members focus on growing the community, MBC sources opportunities that benefit and profit the community. Collective negotiating power allows us to bring the best deals directly to you.</p>
                        </div>
                        <div className='block md:hidden'><img src='assets/Thriving2.png' className='my-5 mx-auto'></img></div>
                        <div className='relative  md:flex justify-start items-center md:py-20 md:mt-10 lg:mt-0 lg:py-10'>
                            <img src='assets/Thriving.png' className='hidden lg:block'></img>
                            <div className='lg:w-[50%] md:absolute lg:pl-10 bg-[#E43875] lg:bg-transparent p-5 rounded-md shadow-md md:shadow-none '>
                                <p className='text-lg' data-aos='fade-right' data-aos-duration="1500">What We Do</p>
                                <div className='my-2 w-[10rem] h-[2px] bg-[#F98736]' data-aos='fade-right' data-aos-duration="1750"></div>
                                <p data-aos='zoom-in' data-aos-duration="2000">At My Business Club, we empower entrepreneurs through a community-centric model that leverages education, blockchain technology, and an innovative referral program. Our goal is to help you achieve financial independence and success through collaborative efforts and shared opportunities.</p>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className='w-[90%] md:w-[85%] mx-auto flex justify-end md:z-10 md:absolute '>
                        <div className='md:w-[50%]'><img src='assets/Thriving2.png' className='hidden md:block' data-aos='zoom-in' data-aos-duration="1000"></img></div>
                    </div>
                </div>
                <div className='sec-4 pb-10 md:pb-0 overflow-hidden'>
                    <div className='w-[90%] mx-auto md:mx-0 flex flex-col md:flex-row justify-between'>
                        <div className='md:w-[60%]' data-aos='fade-up' data-aos-duration="1000">
                            <img src='/assets/Asset.png'></img>
                        </div>
                        <div className='md:w-[40%] grid content-center' data-aos='fade-up' data-aos-duration="1250">
                            <p className='font-bold font-Artemus text-3xl md:text-5xl lg:text-6xl lg:-translate-x-[110px] uppercase text-shadow-custom mt-5'>Referral Benefits</p>
                            <p className='mt-10 pb-2 border-b' data-aos='fade-up' data-aos-duration="1500">Join the Club  -Start by becoming a member for just $50 per year.</p>
                            <p className='mt-5 pb-2 border-b' data-aos='fade-up' data-aos-duration="1750">Empower Your Community: Refer new members and help the community grow. Each referral unlocks new earning levels and opportunities.</p>
                            <p className='mt-5 pb-2 border-b' data-aos='fade-up' data-aos-duration="2000">Join the Club  -Start by becoming a member for just $50 per year.</p>
                            <div className='flex gap-3 mt-10'>
                                <NavLink to='/Login'><button className='bg-gradient-to-r from-[#E43875] to-[#F98736] hover:from-[#F98736] hover:to-[#E43875] duration-500 rounded-full px-4 py-1 border-2 font-bold text-sm' data-aos='fade-left' data-aos-duration="2250">Become a member</button></NavLink>
                                <NavLink to='/Membership'><button class="relative inline-flex items-center justify-center px-4  py-1 font-bold overflow-hidden text-sm text-black bg-white rounded-full group" data-aos='fade-left' data-aos-duration="2500">
                                    <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-black rounded-full group-hover:w-56 group-hover:h-56"></span>
                                    <span class="relative group-hover:text-white">Know more <FontAwesomeIcon icon={faArrowRight} className='-rotate-45' /></span>
                                </button></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sec-5 py-10'>
                    <div className='w-[90%] md:w-[85%] mx-auto'>
                        <p className='font-bold font-Artemus text-3xl md:text-5xl lg:text-6xl uppercase text-shadow-custom mt-10' data-aos='fade-right' data-aos-duration="1000">Entrepreneurs</p>
                        <p className='font-bold mt-5 lg:mt-3 text-xl ' data-aos='fade-up' data-aos-duration="1250">Our Top Users and Their Earnings</p>
                        <div className='flex flex-col md:flex-row gap-10 md:gap-0 '>
                            <div className='md:w-[50%] flex items-center '>
                                <div className='w-[100%] md:translate-x-[20%] lg:translate-x-[30%]'>
                                    <div className='w-[100%] shadow-lg border-2 rounded-3xl py-10 lg:py-16 px-5  lg:px-14 mt-10 font-bold  z-0'>
                                        <ul className="md:w-[80%] lg:w-[70%] space-y-2">
                                            {/*
                                            <li className="flex justify-between" data-aos='fade-right' data-aos-duration="1000">
                                                <p className='flex gap-2 items-center'>
                                                    <FontAwesomeIcon icon={faCircle} className='text-[5px]' />Profit
                                                </p>
                                                <span className=''>$360.00 - $50.00</span>
                                            </li>
                                            <li className="flex justify-between" data-aos='fade-right' data-aos-duration="1250">
                                                <p className='flex gap-2 items-center'>
                                                    <FontAwesomeIcon icon={faCircle} className='text-[5px]' />Net Profit Margin
                                                </p>
                                                <span className=''>($330.00 / $360.00) * 100%</span>
                                            </li>
                                            <li className="flex justify-between" data-aos='fade-right' data-aos-duration="1500">
                                                <p className='flex gap-2 items-center'>
                                                    <FontAwesomeIcon icon={faCircle} className='text-[5px]' />Net Profit Margin
                                                </p>
                                                <span className=''>91.67%</span>
                                            </li>
                                            */}
                                            <li className="flex gap-5" data-aos='fade-right' data-aos-duration="1000">
                                                <p className='flex gap-2 items-center w-[50%]'>
                                                    <FontAwesomeIcon icon={faCircle} className='text-[5px]' />First -
                                                </p>
                                                <span className='w-[50%]'>{data[0]?.netProfitMargin}%</span>
                                            </li>
                                            <li className="flex gap-5" data-aos='fade-right' data-aos-duration="1250">
                                                <p className='flex gap-2 items-center w-[50%]'>
                                                    <FontAwesomeIcon icon={faCircle} className='text-[5px]' />Second -
                                                </p>
                                                <span className='w-[50%]'>{data[1]?.netProfitMargin}%</span>
                                            </li>
                                            <li className="flex gap-5" data-aos='fade-right' data-aos-duration="1500">
                                                <p className='flex gap-2 items-center w-[50%]'>
                                                    <FontAwesomeIcon icon={faCircle} className='text-[5px]' />Third -
                                                </p>
                                                <span className='w-[50%]'>{data[2]?.netProfitMargin}%</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <NavLink to='/Membership'><button className='bg-gradient-to-r from-[#E43875] to-[#F98736] hover:from-[#F98736] hover:to-[#E43875] duration-500 rounded-full px-4 py-1 border-2 font-bold text-sm mt-7' data-aos='fade-up' data-aos-duration="2250">Start Your Earnings Now</button></NavLink>
                                </div>
                            </div>
                            <div className='md:w-[50%] flex items-center z-10'><img src='/assets/Group 33.png' className='md:w-[85%]' data-aos='fade-left' data-aos-duration="1500"></img></div>
                        </div>
                    </div>
                </div>
                <div className='sec-6 pt-10 pb-10 md:pb-28'>
                    <div className='w-[100%] relative md:flex items-end justify-end' data-aos='fade-up' data-aos-duration="1000">
                        <img src='assets/grouppeople.png' className='w-full'></img>
                        <div className='w-[90%] mx-auto md:mx-0 md:w-[50%] bg-white p-5 md:p-10 rounded-md shadow-lg md:absolute md:-translate-x-[5rem] -translate-y-7 md:translate-y-14'>
                            <p className='font-bold font-Artemus text-3xl md:text-4xl lg:text-6xl uppercase bg-gradient-to-r from-[#E43875] to-[#F98736] bg-clip-text text-transparent'>Transform Your Journey</p>
                            <p className='font-bold text-black text-lg'>Join My Business Club's Network of Entrepreneurs!</p>
                            <form onSubmit={handleSubmit}>
                                <div className='flex flex-col lg:flex-row gap-5 mt-7 w-full'>
                                    <input
                                        className="bg-[#EFEFEF] mt-2 rounded-xl px-5 py-2 focus:outline-none font-semibold text-[#F23985] w-[100%] lg:w-[77%]"
                                        value={email}
                                        onChange={(e) => setEmails(e.target.value)}
                                        placeholder='Enter your email id to subscribe our newsletter.'
                                    />
                                    <button
                                        type="submit"
                                        className='bg-gradient-to-r from-[#E43875] to-[#F98736] lg:w-[23%]  rounded-full  py-2 shadow-md text-shadow-custom'
                                    >
                                        Subscribe us
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
