import React, { useState, useEffect } from 'react';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronLeft, faChevronRight, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from '../../../config';
import toast, { Toaster } from "react-hot-toast";

export default function UserKYC() {

    const user = useSelector((state) => state.user);
    const email = user[0]?.email;
    const CusID = user[0]?.CusID;

    const [tableEntries, setTableEntries] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [isCustID, setIsCustID] = useState(false);
    const [Passport, setPassport] = useState(false);
    const [Address, setAddress] = useState(false);
    const [PassportFile, setPassportFile] = useState(null);
    const [AddressFile, setAddressFile] = useState(null);
    const [PassFileName, setPassFileName] = useState(null);
    const [AddFileName, setAddFileName] = useState(null);
    const [userData, setUserData] = useState(null);

    const fetchKYCData = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getKYCDatas?CusID=${CusID}`);
            if (response.data.status === true) {
                setTableEntries(response.data.data);
            } else {
                setTableEntries([]);
            }
        } catch (error) {
            console.error('Error fetching KYC data:', error);
        }
    };

    useEffect(() => {
       
        if (CusID !== null) {
            fetchKYCData();
        }
    }, [CusID]);

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const openImageInNewTab = (entity) => {
        window.open(`/documents/${entity}`, '_blank');
    };

    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const handleOpenCustID = () => {
        setIsCustID(!isCustID);
    };

    const handlePassport = () => {
        setPassport(!Passport);
    };

    const handleAddress = () => {
        setAddress(!Address);
    };

    const handlePassportFile = (e) => {
        const selectedFile = e.target.files[0];
        setPassportFile(selectedFile);
        setPassFileName(selectedFile ? selectedFile.name : '');
    };

    const handleAddressFile = (e) => {
        const selectedFile = e.target.files[0];
        setAddressFile(selectedFile);
        setAddFileName(selectedFile ? selectedFile.name : '');
    };

    const uploadFile = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('CusID', CusID);
        formData.append('doc_type', type);

        try {
            const response = await axios.post(`${SERVER_URL}/api/uploadUsersKYC`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.success) {
                toast.success("Uploaded Successfully!");
                fetchKYCData()
            } else {
                console.error('Upload failed:', response.data.message);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        const options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 bg-white/10 rounded-xl shadow-md mt-5'>
                <div className='flex items-center justify-between'>
                    <h1 className='font-bold text-xl font-Artemus uppercase'>Identity Verification</h1>
                    <div className='flex gap-1 items-center rounded-full px-2 py-1 bg-[#00cc00]'>
                        <FontAwesomeIcon icon={faCheckCircle} />
                        <p className='text-sm font-bold'>Verified</p>
                    </div>
                </div>
                <p className='mt-5 font-semibold'>Before submitting Please read the following KYC Process Information</p>
                <ul className='pl-7 text-sm mt-1'>
                    <li className='list-disc'>All Document Upload Image Format and All Images Quality Good Required</li>
                    <li className='list-disc'>Once Upload Document Please Wait to Admin Approved User Document</li>
                </ul>
                <div className='border border-dashed mt-3 py-3 text-center rounded-xl'>
                    <p>KYC Documents Verified Successfully....!</p>
                </div>
                {/* <div className='mt-3 border rounded-xl p-3 hover:shadow-md duration-500'>
                    <div className='flex justify-between items-center cursor-pointer' onClick={handleOpenCustID}>
                        <h1 className='font-semibold'>Customer Identity Verification</h1>
                        <FontAwesomeIcon icon={faChevronDown} className={`duration-500 ${isCustID ? 'rotate-180' : ''}`} />
                    </div>
                    <div className={`transition-all duration-700 overflow-hidden px-2 ${isCustID ? 'max-h-[1000px]' : 'max-h-0'}`}>
                        <div className='grid md:grid-cols-3 gap-3 md:gap-5 mt-5'>
                            <div className='border hover:border-sky-400 duration-500 rounded-md px-3 py-2'>
                                <p className='text-xs'>Cust ID</p>
                                <h1 className='font-semibold'>494929</h1>
                            </div>
                            <div className='border hover:border-sky-400 duration-500 rounded-md px-3 py-2'>
                                <p className='text-xs'>Login ID</p>
                                <h1 className='font-semibold'>MBC</h1>
                            </div>
                            <div className='border hover:border-sky-400 duration-500 rounded-md px-3 py-2'>
                                <p className='text-xs'>Full Name</p>
                                <h1 className='font-semibold'>Kalpesh</h1>
                            </div>
                        </div>
                        <div className='rounded-md shadow-md duration-500 border mt-5'>
                            <div className={`flex justify-between items-center cursor-pointer p-3 ${Passport ? 'border-b' : ''}`} onClick={handlePassport}>
                                <p>ID / Passport Documents</p>
                                <FontAwesomeIcon icon={faChevronDown} className={`duration-500 ${Passport ? 'rotate-180' : ''}`} />
                            </div>
                            <div className={`transition-all duration-700 overflow-hidden ${Passport ? 'max-h-[500px]' : 'max-h-0'}`}>
                                {tableEntries.some(entry => entry.type === 'ID') ? (
                                    <div className='flex flex-col md:flex-row justify-center gap-5 py-2 px-3 lg:px-0'>
                                        <div className='md:w-[30%]'>
                                            <img src={tableEntries.find(entry => entry.type === 'ID').doc} alt='Passport Document' />
                                        </div>
                                        <div className='md:w-[30%] text-white font-semibold'>
                                            <p className='flex md:flex-col lg:flex-row'><span className='w-[50%] text-white/90 font-normal'>Type</span><span>{tableEntries.find(entry => entry.type === 'ID').name}</span></p>
                                            <p className='flex md:flex-col lg:flex-row'><span className='w-[50%] text-white/90 font-normal'>Uploaded</span><span>{tableEntries.find(entry => entry.type === 'ID').date}<br />{tableEntries.find(entry => entry.type === 'ID').time}</span></p>
                                            <p className='flex md:flex-col lg:flex-row'><span className='w-[50%] text-white/90 font-normal'>Expires</span><span></span></p>
                                        </div>
                                        <div className='md:w-[30%]'>
                                            <p className='flex md:flex-col lg:flex-row'><span className='w-[40%] md:w-[50%] font-semibold'>Status :</span><span className='bg-[#00bb00] px-3 rounded font-semibold'><FontAwesomeIcon icon={faCheckCircle} /> {tableEntries.find(entry => entry.type === 'ID').status}</span></p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='p-3'>
                                        <p className='font-semibold'>Upload ID / Passport Document</p>
                                        <input type='file' className='pt-2 pb-4 pl-8' onChange={handlePassportFile} />
                                        {PassportFile && (
                                            <button
                                                className='bg-blue-500 text-white px-4 py-2 rounded mt-2'
                                                onClick={() => uploadFile(PassportFile, 'Passport')}
                                            >
                                                Upload Passport
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='rounded-md shadow-md duration-500 border mt-5'>
                            <div className={`flex justify-between items-center cursor-pointer p-3 ${Address ? 'border-b' : ''}`} onClick={handleAddress}>
                                <p>Address Documents</p>
                                <FontAwesomeIcon icon={faChevronDown} className={`duration-500 ${Address ? 'rotate-180' : ''}`} />
                            </div>
                            <div className={`transition-all duration-700 overflow-hidden ${Address ? 'max-h-[500px]' : 'max-h-0'}`}>
                                {tableEntries.some(entry => entry.type === 'Address') ? (
                                    <div className='flex flex-col md:flex-row justify-center gap-5 py-2 px-3 lg:px-0'>
                                        <div className='md:w-[30%]'>
                                            <img src={tableEntries.find(entry => entry.type === 'Address').doc} alt='Address Document' />
                                        </div>
                                        <div className='md:w-[30%] text-white font-semibold'>
                                            <p className='flex md:flex-col lg:flex-row'><span className='w-[50%] text-white/90 font-normal'>Type</span><span>{tableEntries.find(entry => entry.type === 'Address').name}</span></p>
                                            <p className='flex md:flex-col lg:flex-row'><span className='w-[50%] text-white/90 font-normal'>Uploaded</span><span>{tableEntries.find(entry => entry.type === 'Address').date}<br />{tableEntries.find(entry => entry.type === 'Address').time}</span></p>
                                            <p className='flex md:flex-col lg:flex-row'><span className='w-[50%] text-white/90 font-normal'>Expires</span><span></span></p>
                                        </div>
                                        <div className='md:w-[30%]'>
                                            <p className='flex md:flex-col lg:flex-row'><span className='w-[40%] md:w-[50%] font-semibold'>Status :</span><span className='bg-[#00bb00] px-3 rounded font-semibold'><FontAwesomeIcon icon={faCheckCircle} /> {tableEntries.find(entry => entry.type === 'Address').status}</span></p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='p-3'>
                                        <p className='font-semibold'>Upload Address Document</p>
                                        <input type='file' className='pt-2 pb-4 pl-8' onChange={handleAddressFile} />
                                        {AddressFile && (
                                            <button
                                                className='bg-blue-500 text-white px-4 py-2 rounded mt-2'
                                                onClick={() => uploadFile(AddressFile, 'Address')}
                                            >
                                                Upload Address
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='p-7 bg-white/10 rounded-xl shadow-md mt-5'>
                    <div className='rounded-2xl'>
                        <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
                            <div className='md:w-[40%]'>
                                <p className='my-auto font-bold text-xl font-Artemus uppercase'>User KYC Log</p>
                            </div>
                            <div className='flex items-center'>
                                <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                    <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                        <option className='text-black' value="5">5</option>
                                        <option className='text-black' value="7">7</option>
                                        <option className='text-black' value="10">10</option>
                                    </select>
                                </p>
                            </div>
                        </div>
                        <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
                            <table className='w-full text-center'>
                                <thead className='font-Artemus uppercase font-bold border-b py-3'>
                                    <tr>
                                        <th className='py-3 px-2'>Cust_ID</th>
                                        <th className='px-2'>Type</th>
                                        <th className='px-2'>UserType</th>
                                        <th className='px-2'>Document</th>
                                        <th className='px-2'>Name</th>
                                        <th className='px-2'>Status</th>
                                        <th className='px-2 whitespace-nowrap'>Date / Time</th>
                                    </tr>
                                </thead>
                                <tbody className='divide-y'>
                                    {currentEntities.map((entity, index) => (
                                        <tr key={index} className='h-16 text-sm text-center rounded-md font-bold'>
                                            <td className='px-5 md:px-2'>{entity.CusID}</td>
                                            <td className='px-5 md:px-2'>{entity.doc_type}</td>
                                            <td className='px-5 md:px-2'>{entity.user_type}</td>
                                            <td className='px-5 md:px-2'>
                                                <div className='mx-auto flex gap-2 justify-center items-center'>
                                                    <div><p className="object-cover">View</p></div>
                                                    <div><FontAwesomeIcon icon={faEye} onClick={() => openImageInNewTab(entity.doc_name)} className='cursor-pointer text-blue-600' /></div>
                                                </div>
                                            </td>
                                            <td className='px-5 md:px-2'>{entity.doc_name}</td>
                                            <td className='px-5 md:px-2 text-xs'>
                                                <div className={`font-bold px-3 py-1 mx-auto rounded-md ${entity.approved_status === 1 ? 'text-white bg-[#198754]' : 'text-black bg-yellow-400'}`}>{entity.approved_status === 1 ? "Approved" : "Pending"}</div>
                                            </td>
                                            <td className='px-5 md:px-2'>{formatDate(entity.created_at)}</td>
                                        </tr>
                                    ))}

                                    {Array.from({ length: emptyRowCount }).map((_, index) => (
                                        <tr key={`empty-${index}`} className='h-16'>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='mt-2 flex justify-between text-xs px-3'>
                            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                            <div className='my-auto flex justify-center items-center gap-1'>
                                <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                                {pageNumbers.map((pageNumber) => (
                                    <button
                                        key={pageNumber}
                                        className={`px - 2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                        onClick={() => handlePageClick(pageNumber)}
                                    >
                                        {pageNumber}
                                    </button>
                                ))}
                                <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                            </div>
                            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
