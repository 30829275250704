import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import '../../Style/UserDashboard.css';
import { SERVER_URL, MORALIS_API_KEY, TOKEN_ADDRESS, PROVIDER_URL, MBCPayOut_ContractAddress, MBCToken_ContractAddress,  } from '../../../config';
import { useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { ethers } from "ethers";
import { MBCPayOut_abi } from '../../../ABI';

export default function Subscription() {

  const user = useSelector((state) => state.user);
  const email = user[0].email;
  const CusID = user[0].CusID;

  console.log(CusID,'member id')
  const [DepositAddress, setDepositAddress] = useState(null)
  const [IncomeAddress, setIncomeAddress] = useState(null)
  const [transactions, setTransactions] = useState([]);
  const [DepositBalance, setDepositBalance] = useState(0);
  const [IncomeBalance, setIncomeBalance] = useState(0);
  const [enteredId, setEnteredId] = useState(CusID);


  const fetchTokenValues = async (address) => {
    try {
        const response = await fetch(`https://deep-index.moralis.io/api/v2.2/wallets/${address}/tokens?chain=bsc`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'X-API-Key': MORALIS_API_KEY
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

    
      const targetToken = data.result.find(token => token.token_address.toLowerCase() === TOKEN_ADDRESS.toLowerCase());
      console.log("balance", data)
    
      if (targetToken) {
        const balance = parseFloat(targetToken.balance) / (10 ** targetToken.decimals); 

        return balance;
      } else {
        return 0;
      }

    } catch (error) {
      console.error('Error fetching token balance:', error);
      return null;
    }
  };

  useEffect(() => {
       
    if (DepositAddress !== null) {
      fetchTokenValues(DepositAddress).then((balance) => setDepositBalance(balance));
    }
    if (IncomeAddress !== null) {
      getUserDetails()
    }
  }, [DepositAddress, IncomeAddress]);

  const getUserDetails = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(PROVIDER_URL);
      let contract = new ethers.Contract(MBCPayOut_ContractAddress, MBCPayOut_abi, provider);
      let getDetails = await contract.getuserDetails(IncomeAddress);
      setIncomeBalance(parseFloat(getDetails[7] / 1e6));
    } catch (err) {
      console.error(err)
    }
  }

  const fetchAddress = async () => {
    const response = await axios.get(`${SERVER_URL}/api/fetchAddress?email=${email}`);
    console.log(response.data.address.deposit_wallet)
    if (response.data.status === true) {
      setDepositAddress(response.data.address.deposit_wallet);
      setIncomeAddress(response.data.address.income_wallet)
    }
  }

  // const fetchSubscriptionHistory = async () => {
  //   try {
  //     let response = await axios.get(`${SERVER_URL}/api/fetchSubsHistory?CusID=${CusID}`);
  //     if (response.data.status === true) {
  //       console.log("checking...",response.data.data)
  //       setTransactions(response.data.data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }



  useEffect(() => {
    const fetchSubscriptionHistory = async () => {
      try {
        let response = await axios.get(`${SERVER_URL}/api/fetchSubsHistory?CusID=${CusID}`);
        if (response.data.status === true) {
          setTransactions(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (CusID) {
      fetchSubscriptionHistory();
      fetchAddress()
    }
  }, [CusID]); // Dependency array includes CusID


  
  

  // useEffect(() => {
  //   if (email !== '') {
  //     fetchAddress();
  //   }
  //   if (CusID !== null) {
  //     fetchSubscriptionHistory();
  //   }
  // }, [email, CusID])



  

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = transactions.slice(indexOfFirstEntity, indexOfLastEntity);
  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage(prevPage => {
      const totalPages = Math.ceil(transactions.length / entitiesPerPage);
      return Math.min(prevPage + 1, totalPages);
    });
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(transactions.length / entitiesPerPage);
  const maxPageNumbersToShow = 5;
  const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
  const pageNumbers = [];

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const [Subscriptionplan, setSubscriptionplan] = useState(false);
  const [Info, setInfo] = useState(false);

  const [selectedSubscription, setSelectedSubscription] = useState({
    type: '',
    amount: 0,
    no: 0
  });

  const [selectedOption, setSelectedOption] = useState('Active Self ID');

  const handleSubscriptionplan = (type, amount, no) => {
    setSubscriptionplan(true);
    setSelectedSubscription({ type, amount, no });
  };

  const handleclose = () => {
    setSubscriptionplan(false);
    setSelectedSubscription({
      type: '',
      amount: 0,
      no: 0
    });
    setInfo(false);
  };

  const handleInfo = () => {
    // if (DepositBalance < selectedSubscription.amount) {
    //   toast.error("Insufficient Balance");
    //   return false;
    // }
    setInfo(!Info);
  };

  const handleSelectChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);

    if (option === 'Active Self ID') {
      setEnteredId(CusID);
    } else {
      setEnteredId('');
    }
  };

  const handleInputChange = (e) => {
    setEnteredId(e.target.value);
  };

  // const confirmSubscription = async () => {
  //   try {
  //     // toast.error('Subscription Cant be Done right now at the Moment');
  //     // return
  //     const response = await axios.post(`${SERVER_URL}/api/subscription`, {
  //       plan: selectedSubscription.type,
  //       plan_id: selectedSubscription.no,
  //       amount: selectedSubscription.amount,
  //       CusID: Number(enteredId),
  //       from_address: DepositAddress,
  //       subscribed_by: CusID,
  //       email: email
  //     });
  //     console.log(response,'response')
  //     if (response.data.status === true) {
  //       toast.success("Subscribed Successfully !!")
  //     } else {
  //       toast.error("Error during Subscription.")
  //     }
  //   } catch (error) {
  //     console.log("Error during Subscription : ", error);
  //     toast.error("Error during Subscription.")
  //   }
  // }


  // const confirmSubscription = async () => {
  //   try {
  //     const responsePromise = axios.post(`${SERVER_URL}/api/subscription`, {
  //       plan: selectedSubscription.type,
  //       plan_id: selectedSubscription.no,
  //       amount: selectedSubscription.amount,
  //       CusID: Number(enteredId),
  //       from_address: IncomeAddress,
  //       subscribed_by: CusID,
  //       email: email
  //     });
  
  //     // Using toast.promise to handle loading, success, and error states
  //     await toast.promise(
  //       responsePromise, 
  //       {
  //         loading: "Transaction is in progress...",
  //         success: (response) => {
  //           console.log(response, 'response');
  //           if (response.data.status === true) {
  //             return "Subscribed Successfully !!";
  //           } else {
  //             return "Error during Subscription.";
  //           }
  //         },
  //         error: "Error during Subscription."
  //       },
  //       {
  //         success: { duration: 5000 },
  //         error: { duration: 5000 }
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Error during Subscription:", error);
  //   }
  // };


  const confirmSubscription = async () => {
    try {
      const responsePromise = axios.post(`${SERVER_URL}/api/subscription`, {
        plan: selectedSubscription.type,
        plan_id: selectedSubscription.no,
        amount: selectedSubscription.amount,
        CusID: Number(enteredId),
        from_address: IncomeAddress,
        subscribed_by: CusID,
        email: email
      });
  
      await toast.promise(
        responsePromise,
        {
          loading: "Transaction is in progress...",
          success: (response) => {
            console.log(response, 'response');
            if (response.data.status === true) {
              return "Subscribed Successfully !!";
            } else {
              return response.data.message || "Error during Subscription.";
            }
          },
          error: (error) => {
            console.error("Error during Subscription:", error);
  
            if (error.response) {
              const statusCode = error.response.status;
              const errorMessage = error.response.data.message || "Error during Subscription.";
              
              if (statusCode === 400) {
                return "Bad Request: Please check your input data.";
              } else if (statusCode === 401) {
                return "Unauthorized: Please check your credentials.";
              } else if (statusCode === 403) {
                return "Forbidden: You do not have access to this resource.";
              } else if (statusCode === 404) {
                return "Not Found: The subscription endpoint is incorrect.";
              } else if (statusCode === 500) {
                return "Internal Server Error: Please try again later.";
              } else {
                return errorMessage;
              }
            } else if (error.request) {
              return "No response from server: Please check your network connection.";
            } else {
              return `Error: ${error.message}`;
            }
          }
        },
        {
          success: { duration: 5000 },
          error: { duration: 5000 }
        }
      );
    } catch (error) {
      console.error("Unexpected Error during Subscription:", error);
    }
  };
  
  

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

  const openImageInNewTab = (hash) => {
    if (hash) {
      window.open(`https://testnet.bscscan.com/tx/${hash}`, '_blank');
    }
  };

  // const [DepositBalance, setDepositBalance] = useState(0)
  const [Team, setTeam] = useState(0)
  const [Subscription, setSubscription] = useState(0)


  // const [IncomeBalance, setIncomeBalance] = useState(0)
  const [history, setHistory] = useState([])
  const [SubscribeHistory, setSubscribeHistory] = useState([])



  const getRecords = async()=>{
      try{
          await axios.get(`${SERVER_URL}/api/getTransRecords?id=${user[0].CusID}`).then((res)=>{
              if(res.data.status == true){
                console.log("get records", res.data)
                  setHistory(res.data.history)
                  // setDepositBalance(res.data.Deposit)
                  // setIncomeBalance(res.data.Incoming)
                  setTeam(res.data.TeamCount)
                  setSubscription(res.data.Subscription)
              }else{
                  setHistory([])
                  // setDepositBalance('0.00')
                  // setIncomeBalance('0.00')
                  setTeam(0)
                  setSubscription('0.00')
              }
          })
      }catch(err){
          console.log(err)
          setHistory([])
      }
  }
  useEffect(()=>{
      getRecords()
      getSubRecords()
  },[user])

  const getSubRecords = async()=>{
    try{
        await axios.get(`${SERVER_URL}/api/getSubscribeHistory?id=${user[0].CusID}`).then((res)=>{
            console.log(user[0].CusID,'sub response');
            // if(res.data.status == true){
            //   setTransactions(res.data.history)
            // }else{
            //   setTransactions([])
            // }
        })
    }catch(err){
        console.log(err)
        setSubscribeHistory([])
      }
}

  return (
    <div data-aos="fadeIn" data-aos-duration='2000'>
      <div className='md:pl-5 py-5 md:pr-10 font-poppins'>
        <div className='flex justify-center gap-5'>
          <div className='border-[2px] md:w-[30%] border-white/50 rounded-md text-center px-2 py-5 shadow-md'>
            <h1 className='tracking-wider uppercase text-sm'>Deposit Wallet</h1>
            <p className='uppercase font-Artemus font-bold text-xl my-1'>$ {Number(DepositBalance).toFixed(2)}</p>
            <p className='text-xs'>Analytics for Deposit Balance</p>
          </div>
          <div className='border-[2px] md:w-[30%] border-white/50 rounded-md text-center p-2 py-5 shadow-md'>
            <h1 className='tracking-wider uppercase text-sm'>Income Wallet</h1>
            <p className='uppercase font-Artemus font-bold text-xl my-1'>$ {Number(IncomeBalance).toFixed(2)}</p>
            <p className='text-xs'>Analytics for Income Balance</p>
          </div>
        </div>
        {Subscriptionplan ? (
          <div className='mt-10 border rounded-md px-2 md:px-10 py-5 md:py-7'>
            <div>
              <div className='flex justify-between'>
                <h1 className='text-xl font-bold'>Subscribe Now</h1>
                <div className='cursor-pointer' onClick={handleclose}>
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
              {Info ? (
                <div className='w-[95%] md:w-[60%] lg:w-[40%] p-5 mx-auto mt-3'>
                  <p className='text-sm font-bold'>Information</p>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Subscription</p>
                    <p className='font-semibold 2xl:text-lg'>{selectedSubscription.type}</p>
                  </div>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Subscription Amount ($)</p>
                    <p className='font-semibold 2xl:text-lg'>{selectedSubscription.amount}</p>
                  </div>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Wallet</p>
                    <p className='font-semibold 2xl:text-lg'>Deposit Wallet</p>
                  </div>
                  <div className='border border-white/30 rounded-md py-2 px-3 mt-2'>
                    <p className='text-white/75 text-xs 2xl:text-sm'>Choose Activation Type</p>
                    <select
                      className='bg-transparent w-full outline-none'
                      value={selectedOption}
                      onChange={handleSelectChange}
                    >
                      <option className='text-black'>Active Self ID</option>
                      <option className='text-black'>Active Others ID</option>
                    </select>
                  </div>
                  {selectedOption === 'Active Others ID' && (
                    <input
                      className='w-[100%] bg-transparent border border-white/30 rounded-md h-[58px] py-2 px-3 mt-2 placeholder:text-white'
                      placeholder='Enter UserID'
                      value={enteredId}
                      onChange={handleInputChange}
                    />
                  )}
                  <div className='flex justify-center mt-2'>
                    <button className=' bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-5 py-2 rounded-md ' onClick={confirmSubscription}>
                      Buy Now
                      {/* Send OTP */}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <p className='font-bold text-center mt-5'>Please Select Wallet</p>
                  <div
                    className='w-[90%] md:w-[50%] lg:w-[30%] border rounded-xl shadow-md shadow-black/30 text-center p-5 mx-auto mt-3 cursor-pointer duration-500 hover:scale-110'
                    onClick={handleInfo}
                  >
                    <h1 className='tracking-wider uppercase '>Deposit Wallet</h1>
                    <p className='uppercase font-bold font-Artemus text-2xl my-1'>$ {Number(DepositBalance).toFixed(2)}</p>
                    <p className='text-sm'>Analytics for Deposit Balance</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className='mt-10 border rounded-md px-10 py-7 grid grid-cols-2 lg:grid-cols-4 gap-5'>
            {[
              { type: 'MEMBER', no: 1, amount: 50, img: '/assets/Starter.png' },
              { type: 'SILVER', no: 1, amount: 200, img: '/assets/StarterPackages_01.png' },
              { type: 'GOLD', no: 2, amount: 600, img: '/assets/StarterPackages_02.png' },
              { type: 'PLATINUM', no: 3, amount: 1500, img: '/assets/StarterPackages_03.png' },
              { type: 'EXECUTIVE', no: 4, amount: 3000, img: '/assets/StarterPackages_04.png' },
              { type: 'AMBASSADOR', no: 5, amount: 5000, img: '/assets/StarterPackages_05.png' },
              { type: 'PRESIDENT', no: 6, amount: 10000, img: '/assets/StarterPackages_06.png' },
              // { type: 'Subscription - 7', no: 7, amount: 5000, img: '/assets/StarterPackages_07.png' },
              // { type: 'Subscription - 8', no: 8, amount: 10000, img: '/assets/StarterPackages_08.png' },
            ].map((plan, index) => (
              <div className="flip-card mx-auto " key={index}>
                <div className="flip-card-inner rounded-xl">
                  <div className="flip-card-front rounded-xl border flex items-center justify-center">
                    <img src={plan.img} className='' alt={plan.type} />
                  </div>
                  <div className="flip-card-back py-3 px-3 border grid content-end rounded-xl">
                    <h1 className='font-bold text-3xl'>${plan.amount}</h1>
                    <p className='mt-2 text-sm'>{plan.type}</p>
                    <button
                      className='mt-5 bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold w-full py-2 rounded-md'
                      onClick={() => handleSubscriptionplan(plan.type, plan.amount, plan.no)}
                    >
                      Subscribe Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className='rounded-2xl mt-10'>
          <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
            <div className=''>
              <p className='my-auto font-bold text-xl font-Artemus'>Subscription Purchase History</p>
            </div>
            <div className='flex items-center'>
              <p className='text-end text-sm px-3 my-auto'>Show no of entity
                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                  <option className='text-black' value="5">5</option>
                  <option className='text-black' value="7">7</option>
                  <option className='text-black' value="10">10</option>
                </select>
              </p>
            </div>
          </div>
          <div className='mb-5 border  rounded-xl overflow-hidden overflow-x-auto'>
            <table className='w-full text-center  '>
              <thead className='uppercase font-bold text-sm border-b py-3'>
                <tr>
                  <th className='py-3'>SI.No</th>
                  <th>Cust ID</th>
                  <th>Hash</th>
                  <th>Date</th>
                  <th>Package Amount</th>
                  <th>Package</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className='divide-y'>
                {currentEntities.map((entity, index) => (
                  <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                    <td className='px-5 md:px-0 font-Artemus'>{index + 1}</td>
                    <td className='px-5 md:px-0'>{entity.CustID ?? entity.CusID}</td>
                    <td className='px-5 md:px-0 cursor-pointer' onClick={() => openImageInNewTab(entity.TrID ?? entity.trx_hash)}>{(entity.TrID ?? entity.trx_hash).slice(0,7)}...</td>
                    <td className='px-5 md:px-0'>{formatDate(entity.dot ?? entity.createdAt)}</td>
                    <td className='px-5 md:px-0'>$ {Number(entity.amount).toFixed(0)}</td>
                    <td className='px-5 md:px-0'>{entity.plan}</td>
                    <td className='px-5 md:px-0 text-green-500'>SUCCESS</td>
                  </tr>
                ))}
                {/* Add empty rows if needed */}
                {Array.from({ length: emptyRowCount }).map((_, index) => (
                  <tr key={`empty-${index}`} className='h-16'>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='mt-2 flex justify-between text-xs px-3'>
            <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
            <div className='my-auto flex justify-center items-center gap-1'>
              <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
              {pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
              <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
            </div>
            <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
          </div>
        </div>
      </div>
    </div>
  )
}
