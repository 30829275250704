import React, { memo, useState, useEffect } from 'react'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from '../../../config';
import toast, { Toaster } from "react-hot-toast";

export default function Security() {

    const user = useSelector((state) => state.user);
    const email = user[0]?.email;
    const CusID = user[0]?.CusID;

    const [tableEntries, setTableEntries] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);
    const [resetPassword, setResetPassword] = useState(false);

    const [otp, setOtp] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [visible1, setVisible1] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [visible3, setVisible3] = useState(false);

    const handlePasswordToggle1 = () => {
        setVisible1(!visible1);
    };

    const handlePasswordToggle2 = () => {
        setVisible2(!visible2);
    };

    const handlePasswordToggle3 = () => {
        setVisible3(!visible3);
    };

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle pagination clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleResetPassword = () => {
        setResetPassword(!resetPassword);
    };

    const handleSubmit = async () => {
        if (newPassword !== confirmPassword) {
            toast.error("New password and Confirm password do not match!");
            return;
        }
        try {
            const response = await axios.post(`${SERVER_URL}/api/changePassword`, {
                "otp": otp,
                "currentPassword": currentPassword,
                "newPassword": newPassword,
                "CusID": CusID
            });

            if (response.data.status === true) {
                toast.success('Password changed successfully!');
                setResetPassword(false);
                setOtp("");
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");

            } else if (response.data.status === false) {
                toast.error(response.data.message);
            } else {
                toast.error("An error occurred. Please try again");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred. Please try again.");
        }
    };

    const getLoginDetials = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getDeviceLogin?CusID=${CusID}`);
            if (response.data.status === true) {
                setTableEntries(response.data.data);
            } else { 
                setTableEntries([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (CusID !== null) {
            getLoginDetials();
        }
    }, [CusID]);


    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        const options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='bg-white/10 shadow-md rounded-xl p-5 mt-5'>
                <p className='font-bold text-lg md:text-xl font-Artemus uppercase'>Change Login Password</p>
                {resetPassword ? (
                    <div className='mt-5'>
                        <div className='grid md:grid-cols-2 gap-5'>
                            <input
                                className='w-[100%] bg-transparent py-4 outline-none rounded-md border px-3 placeholder:text-white'
                                placeholder='Enter OTP'
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <div className="relative">
                                <input
                                    className='w-[100%] bg-transparent py-4 outline-none rounded-md border px-3 placeholder:text-white'
                                    type={visible1 ? 'text' : 'password'}
                                    placeholder='Current password'
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                                <span
                                    className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
                                    onClick={handlePasswordToggle1}
                                >
                                    <FontAwesomeIcon icon={visible1 ? faEye : faEyeSlash} />
                                </span>
                            </div>
                            <div className="relative">
                                <input
                                    className='w-[100%] bg-transparent py-4 outline-none rounded-md border px-3 placeholder:text-white'
                                    type={visible2 ? 'text' : 'password'}
                                    placeholder='New password'
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <span
                                    className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
                                    onClick={handlePasswordToggle2}
                                >
                                    <FontAwesomeIcon icon={visible2 ? faEye : faEyeSlash} />
                                </span>
                            </div>
                            <div className="relative">
                                <input
                                    className='w-[100%] bg-transparent py-4 outline-none rounded-md border px-3 placeholder:text-white'
                                    type={visible3 ? 'text' : 'password'}
                                    placeholder='Confirm New password'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <span
                                    className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
                                    onClick={handlePasswordToggle3}
                                >
                                    <FontAwesomeIcon icon={visible3 ? faEye : faEyeSlash} />
                                </span>
                            </div>
                        </div>
                        <h1 className='text-lg font-semibold mt-5'>Password Requirements:</h1>
                        <div className='mt-1 pl-5'>
                            <li>Minimum 8 characters long - the more, the better</li>
                            <li>At least one lowercase character</li>
                            <li>At least one number, symbol, or whitespace character</li>
                        </div>
                        <div className='mt-5 flex justify-end gap-3'>
                            <button
                                className='px-3 py-1 rounded bg-[#00bb00] font-bold'
                                onClick={handleSubmit}
                            >
                                Change Login Password
                            </button>
                            <button className='px-3 py-1 rounded bg-[#dd0000] font-bold' onClick={handleResetPassword}>Cancel</button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p className='mt-5 md:mt-10 text-center'>Click On Send OTP to Change Your Password</p>
                        <div className='flex justify-center mt-5'>
                            <button
                                className=' bg-gradient-to-r from-[#F23985] to-[#FB9236] shadow-md shadow-black/30 text-sm font-semibold px-10 py-3 rounded-md '
                                onClick={handleResetPassword}
                            >
                                Send OTP
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* Your table and pagination code */}
            <div className='mt-5'>
                <div className='rounded-2xl'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
                        <div className='md:w-[40%]'>
                            <p className='my-auto font-bold text-xl font-Artemus uppercase'>Recent Devices</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className=' font-Artemus uppercase font-bold text-lg border-b py-3'>
                                <tr>
                                    <th className='py-3'>ADDRESS</th>
                                    <th>DATE</th>
                                    <th>CLIENT ADDRESS</th>
                                    <th>STATUS</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-0 font-Artemus'>{entity.ip_address}</td>
                                        <td className='px-5 md:px-0'>{formatDate(entity.created_at)}</td>
                                        <td className='px-5 md:px-0'>{entity.device_type}</td>
                                        <td className={`px-5 md:px-0 font-bold text-[#00ff00]`}>Success</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='space-x-2 flex'>
                            {Array.from({ length: Math.ceil(tableEntries.length / entitiesPerPage) }).map((_, index) => (
                                <button
                                    key={index + 1}
                                    className={`bg-white text-black font-bold rounded-md px-2 py-1 ${currentPage === index + 1 ? 'bg-gray-400' : ''}`}
                                    onClick={() => handlePageClick(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentPage === Math.ceil(tableEntries.length / entitiesPerPage)}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
