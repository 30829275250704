import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { SERVER_URL } from "../../config";
AOS.init();

const Signup = () => {

  const [visible, setVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const location = useLocation();
  const [sponsorID, setSponsorID] = useState('');
  const [isRefIdReadOnly, setIsRefIdReadOnly] = useState(false);

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get('ref_id'); 
  };

  useEffect(() => {
    const ref_id = getQueryParams();
    if (ref_id) {
      setSponsorID(ref_id); 
      setIsRefIdReadOnly(true); 
    }
  }, [location.search]);

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handlePasswordVisibility = () => {
    setVisible(!visible);
  };




const SignupUser = async () => {
	console.log("comming");
  if (validateForm()) {

      //  toast.error('New User Registration is Not Allowed at the Moment');
   // return false

    const toastId = toast.loading('Please wait while registering...');

    try {
      const response = await axios.post(`${SERVER_URL}/api/Signup`, {
        sponsorID, username, name, email, country, password,
      });

      console.log(response);

      if (response.data.status === true) {
        toast.dismiss(toastId); 
        toast.success('Registration successful!');

       
        setTimeout(() => {
          navigate('/Login');
        }, 2000);

      } else if (response.data.status === false) {
        toast.dismiss(toastId); 
        toast.error(response.data.message);
      }

    } catch (error) {
      console.error('Error during registration:', error);
      toast.dismiss(toastId); 
      toast.error('An error occurred. Please try again later.');
    }
  }
};


  const validateForm = () => {
    const newErrors = {};
    // Corrected regex for password validation
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()+])[a-zA-Z0-9!@#$%^&*()+]{8,}$/;
  
    if (!sponsorID) newErrors.sponsorID = "Sponsor ID is required";
    if (!username) newErrors.username = "Username is required";
    if (!name) newErrors.name = "Name is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email address is invalid";
    }
    if (!country) newErrors.country = "Country is required";
    if (!password) {
      newErrors.password = "Password is required";
    } else if (!password.match(passwordRegex)) {
      newErrors.password = "Password must contain at least one number, one letter, and one special character";
    }
  
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Password mismatch";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  


  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1317016292450678',
        cookie: true,
        xfbml: true,
        version: 'v20.0'
      });
      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const handleFacebookLogin = () => {
    window.FB.login(response => {
      if (response.authResponse) {
        window.FB.api('/me', { fields: 'id,name,email' }, async function (userInfo) {
          try {
            const result = await fetch(`${SERVER_URL}/signup/facebook`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                facebookID: userInfo.id,
                name: userInfo.name,
                email: userInfo.email
              })
            });

            const data = await result.json();

            if (data.success) {
              alert("registered sucessfully");
              navigate('/Login');
            } else {
              alert(data.message);
              navigate('/Login');
            }
          } catch (error) {
            console.error('Error during Facebook login:', error);
            alert('An error occurred. Please try again later.');
          }
        });
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'email' });
  };



  const [isGoogleRegister, setIsGoogleRegister] = useState(true);
  const [googleFormData, setGoogleFormData] = useState({
    firstName: "",
    email: "",
    googleId: "",
  });

  const clientId =
    "763708000678-hdpn5ipmfl6fqssocnqsmspaem85muuk.apps.googleusercontent.com";

  const handleGoogleSignUp = () => {
    console.log("SignUp with Google button clicked");
    setIsGoogleRegister(true);
    console.log(isGoogleRegister);
  };

  const onSuccess = async (res) => {
    try {
      console.log("isGoogleRegister onSuccess", isGoogleRegister);

      if (isGoogleRegister) {


        const { googleId, email, givenName } = res.profileObj;

        const formData = {
          firstName: givenName,
          email,
          googleId,
        };
        setGoogleFormData(formData);
        //  console.log("GoogleFormData", googleFormData);

        const response = await axios.post(`${SERVER_URL}/api/GoogleEmail_Registercheck_User`, { email });

        if (response.data.message === "exists") {
          toast.error("Email already exists. Please login.");
          navigate("/Login");
        } else if (response.data.message === "not_exists") {

          await googleRegister(formData);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFailure = (error) => {
    console.error("Google sign-up failed:", error);

    setIsGoogleRegister(false);
  };

  const googleRegister = async (formData) => {
    try {
      //  console.log("Data sending", formData);
      const response = await axios.post(`${SERVER_URL}/api/SignUpGoogleUser`, formData);
      // console.log(response.data);
      if (response.data.status === "success") {
        //  console.log("Registration successful");
        navigate("/Login");
      } else {
        console.error("Google registration failed:", response.data.message);
      }
    } catch (error) {
      console.error("Google registration error:", error.message);
    }
  };

  useEffect(() => {
    const initGoogleClient = async () => {
      try {
        await gapi.client.init({
          clientId: clientId,
          scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
        });
      } catch (error) {
        console.error("Error initializing Google API client:", error);
      }
    };

    gapi.load("client:auth2", initGoogleClient);
  }, [clientId]);






  return (
    <div>
      <Header />
      <Toaster />
      <div
        className="sec-1 bg-black bg-cover min-h-screen font-poppins text-white pt-24 overflow-hidden"
        style={{ backgroundImage: "url(../assets/NewMBCbg.png)" }}
      >
        <div className="w-[90%] mx-auto flex justify-center items-center py-5">
          <div className="flex lg:flex-row flex-col justify-center">
            <div
              className="lg:w-[60%] w-full"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div>
                <h1 className="md:text-6xl text-4xl font-Artemus font-bold uppercase text-shadow-custom">
                  My business club
                </h1>
                <p className="font-bold md:text-lg text-sm mt-2">
                  Welcome to private membership “Business Club”
                </p>
              </div>
              <div className="mt-10 w-full">
                <img src="assets/Rectangle_39637.png" className="lg:w-10/12" />
                <div className="flex justify-end">
                  <div className="bg-white/60 sm:py-7 py-4 sm:px-5 px-2 rounded-2xl border sm:w-32 w-24 lg:-translate-x-20 md:-translate-y-24 -translate-y-10 backdrop-blur-md">
                    <img src="../assets/Icon (2).png" />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="lg:w-[40%]"
              data-aos="fade-left"
              data-aos-duration="2000"
            >
              <div className="border-2 rounded-md py-5 md:px-10 px-3">
                <div>
                  <p className="sm:text-5xl text-4xl font-bold">SIGN UP</p>
                  <p className="font-bold mt-1 text-sm px-2">
                    Sign in with email address
                  </p>
                </div>
                <div className="border-b-2 py-3 pb-7">
                  <div className="mt-2 relative">
                    <input
                      type="text"
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.sponsorID ? 'border-red-500' : ''}`}
                      placeholder="Enter Sponsor ID"
                      value={sponsorID}
                      onChange={(e) => setSponsorID(e.target.value)}
                      readOnly={isRefIdReadOnly}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.sponsorID && <p className="px-2 mt-1 text-xs ">{errors.sponsorID}</p>}


                  <div className="mt-2 relative">
                    <input
                      type="text"
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.username ? 'border-red-500' : ''}`}
                      placeholder="Enter your UserID or Username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.username && <p className="px-2 mt-1 text-xs ">{errors.username}</p>}

                  <div className="mt-2 relative">
                    <input
                      type="text"
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.name ? 'border-red-500' : ''}`}
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.name && <p className="px-2 mt-1 text-xs ">{errors.name}</p>}


                  <div className="mt-2 relative">
                    <input
                      type="text"
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm ${errors.email ? 'border-red-500' : ''}`}
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.email && <p className="px-2 mt-1 text-xs ">{errors.email}</p>}


                  <div className="mt-2 relative">
                    <select
                      value={country}
                      onChange={handleCountryChange}
                      className={`w-full focus:outline-none rounded-lg py-3 px-10 text-[#A4A4A4] text-sm bg-white appearance-none ${errors.country ? 'border-red-500' : ''}`}
                    >
                      <option value="" disabled>
                        Select Country
                      </option>
                      {countries.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.country && <p className="px-2 mt-1 text-xs ">{errors.country}</p>}


                  <div className="mt-3 relative">
                    <input
                      type={visible ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={`w-full rounded-lg focus:outline-none py-3 px-10 text-[#A4A4A4] text-sm ${errors.password ? 'border-red-500' : ''}`}
                      placeholder="Enter the password"
                    />
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                    <button
                      onClick={handlePasswordVisibility}
                      className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[40px]"
                    >
                      {visible ? (
                        <FontAwesomeIcon
                          icon={faEye}
                          size="sm"
                          className="text-gray-400"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faEyeSlash}
                          size="sm"
                          className="text-gray-400"
                        />
                      )}
                    </button>
                  </div>
                  {errors.password && <p className="px-2 mt-1 text-xs">{errors.password}</p>}

                  <div className="mt-3 relative">
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className={`w-full rounded-lg focus:outline-none py-3 px-10 text-[#A4A4A4] text-sm ${errors.confirmPassword ? 'border-red-500' : ''}`}
                      placeholder="Enter the confirm password"
                    />
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-[#A4A4A4] absolute left-3 top-3.5"
                    />
                  </div>
                  {errors.confirmPassword && <p className="px-2 mt-1 text-xs">{errors.confirmPassword}</p>}


                  <div className="mt-5 cursor-pointer" onClick={SignupUser}>
                    <div className="shadow-lg shadow-black/30 bg-white py-1 px-2 rounded-xl">
                      <div className="w-full rounded-xl py-2 bg-gradient-to-r from-[#F23985] to-[#FB9236] font-bold text-center">
                        Sign up
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  {/* <p className="font-semibold text-sm">Or continue with</p> */}
                  {/* <div className="flex justify-between gap-2 mt-2 mb-5">
                    <div className="bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg">
                      <GoogleLogin
                        clientId={clientId}
                        buttonText="SignUp with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={false}
                        prompt="select_account"
                        redirectUri="http://localhost:3000/auth/callback"
                        render={(renderProps) => (
                          <button
                            onClick={() => {
                              handleGoogleSignUp();
                              renderProps.onClick();
                            }}
                            disabled={renderProps.disabled}
                            className="flex items-center gap-2"
                          >
                            <img src="../assets/google.png" className="w-4 h-4" alt="Google Icon" />
                            <p className="font-semibold text-black text-sm">Google</p>
                          </button>
                        )}
                      />
                    </div>
                    <div className="bg-white py-2 flex justify-center items-center gap-2 w-full rounded-lg cursor-pointer" onClick={handleFacebookLogin}>
                      <img src="../assets/fb.png" className="w-5" />
                      <p className="font-semibold text-black text-sm">Facebook</p>
                    </div>
                  </div> */}
                  <p className="text-xs text-center font-semibold">
                    {" "}
                    By registering you with our
                    <span className="text-black font-semibold">
                      {" "}
                      Terms and Conditions.
                    </span>
                  </p>
                  <p className='text-center text-sm font-semibold mt-2'>Already a memeber ? <a href='/Login' className='text-blue-800'>Login</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

