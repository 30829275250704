import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faWallet, faUser, faCube, faBell, faFile, faCreditCard, faMoneyBillTransfer, faAddressCard, faArrowRightFromBracket, faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import DashboardHeader from './DashboardHeader';
import AccountSettings from './Profile/AccountSettings';
import Analysis from './Analysis';
import { Toaster } from 'react-hot-toast';
import Deposit from './Add Funds/Deposit';
import Addfunds from './Add Funds/Addfunds';
import SubscriptionHistory from './Subcription/SubscriptionHistory';
import Subscription from './Subcription/Subscription';
import AllTeam from './My Team/AllTeam';
import DirectTeam from './My Team/DirectTeam';
import InDirectTeam from './My Team/InDirectTeam';
import ActiveTeam from './My Team/ActiveTeam';
import InactiveTeam from './My Team/InactiveTeam';
import VisualStructure from './My Team/VisualStructure';
import DirectIncome from './Reports/DirectIncome';
import LevelIncome from './Reports/LevelIncome';
import Skilleareumai from './Skilleareumai';
import WithdrawIncome from './Withdraw/WithdrawIncome';
import WithdrawReport from './Withdraw/WithdrawReport';
import { useDispatch, useSelector } from "react-redux";
import { setUser } from '../../store';
import { useLocation } from 'react-router-dom';

export default function MyDashboard() {
    const [selectedTab, setSelectedTab] = useState('Dashboard');
    const [activeTab, setActiveTab] = useState('Dashboard');
    const [activeSubTab, setActiveSubTab] = useState(null); // Track sub-tab state
    const [active, setActive] = useState('div1'); // Main tab content visibility
    const [Addfund, setAddfund] = useState(false); // Control dropdown visibility
    const [Subscribe, setSubscribe] = useState(false); // Control dropdown visibility
    const [Team, setTeam] = useState(false); // Control dropdown visibility
    const [Report, setReport] = useState(false); // Control dropdown visibility
    const [withdraws, setwithdraws] = useState(false); // Control dropdown visibility


    const [isOpenNavbar, setOpenNavbar] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleOpenNavbar = () => {
        setOpenNavbar(!isOpenNavbar);
    };

    const user = useSelector((state) => state.user);
    const email = user[0].email;


    const location = useLocation(); 

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const profile = params.get('tab');

        console.log("profile",profile)

        if (profile) {
            setActiveTab(profile); 
            setActive(profile === 'account' ? 'div8' : 'div1'); 
        }
    }, [location]);


    const handleClickOutside = (event) => {
        if (
            (isOpenNavbar && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setOpenNavbar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenNavbar]);


    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setActiveTab(tab === activeTab ? null : tab);

        // Close the AddFund dropdown if any other tab is clicked
        if (tab !== 'AddFund' || 'Subscription' || 'My Directives' || 'Reports' || 'Withdraw') {
            setAddfund(false);
            setSubscribe(false);
            setTeam(false);
            setReport(false);
            setwithdraws(false);
        }

        switch (tab) {
            case 'AddFund':
                handleAnchorClick('div2'); // Display 'AddFund' main tab content
                break;
            case 'Subscription':
                handleAnchorClick('div3');
                break;
            case 'My Directives':
                handleAnchorClick('div4');
                break;
            case 'Reports':
                handleAnchorClick('div5');
                break;
            case 'Withdraw':
                handleAnchorClick('div6');
                break;
            case 'Skilleareum.ai':
                handleAnchorClick('div7');
                break;
            case 'Profile':
                handleAnchorClick('div8');
                break;
            default:
                handleAnchorClick('div1');
        }
    };
    const dispatch = useDispatch();
    const Logout = () => {
        dispatch(setUser(""));
        window.location = '/';
    };
    const handleSubTabClick = (subtab) => {
        setActiveSubTab(subtab); // Set the active sub-tab based on the clicked sub-tab
    };
    const handleAnchorClick = (tab) => {
        setActive(tab); // Set main tab content visibility
    };
    const handleAddfund = () => {
        setAddfund(!Addfund); // Toggle AddFund dropdown visibility
    };
    const handleSubscribe = () => {
        setSubscribe(!Subscribe); // Toggle Subcribe dropdown visibility
    };
    const handleTeam = () => {
        setTeam(!Team); // Toggle team dropdown visibility
    };
    const handleReport = () => {
        setReport(!Report); // Toggle team dropdown visibility
    };
    const handleWithdraw = () => {
        setwithdraws(!withdraws); // Toggle team dropdown visibility
    };

    const iconMap = {
        'cube': faCube,
        'users': faUserGroup,
        'wallet': faWallet,
        'user': faUser,
        'file': faFile,
        'credit': faCreditCard,
        'withdraw': faMoneyBillTransfer,
        'id': faAddressCard,
        'logout':faArrowRightToBracket
    };

    const TabButton = ({ label, tab, icon, onClick }) => (
        <button
            className={`flex justify-center gap-3 w-[80%] md:w-[100%] lg:w-[90%] 2xl:w-[75%] mx-auto uppercase font-bold rounded-xl py-2.5 duration-500
                ${tab === activeTab ? 'bg-gradient-to-r from-[#F23985] via-[#FF99BD] via-[70%] to-[#F23985]' : ''}`}
            onClick={onClick}
        >
            <div className='relative w-[10%]'>
                <FontAwesomeIcon icon={icon} className='text-lg' />
            </div>
            <span className="w-[75%] md:w-[60%] text-start">{label}</span>
        </button>
    );
    const SubTabButton = ({ label, onClick }) => (
        <button
            className={`flex justify-center gap-3 duration-500 font-bold rounded-xl py-1 text-sm 
                ${label === activeSubTab ? 'text-pink-600' : ''}`}
            onClick={onClick}
        >
            <span className="w-[75%] md:w-[70%] text-start">{label}</span>
        </button>
    );




    return (
        <div>
            <div className="fixed inset-0 z-0 ">
                <img src="/assets/NewMBCbg.png" className="h-full w-full object-cover object-top"></img>
            </div>
            <div className="relative z-10 text-white font-Artemus">
                <DashboardHeader selectedTab={selectedTab} />
                <Toaster />
                <div className='p-5 md:flex gap-5 mt-20 md:mt-[5%] lg:mt-[4%] 2xl:mt-[3%]'>
                    <div className="hidden md:block w-[25%] lg:w-[20%] fixed left-0">
                        <nav className='mt-[30%]'>
                            <TabButton label='Dashboard' tab='Dashboard' icon={iconMap['cube']} onClick={() => handleTabClick('Dashboard')} />
                            <TabButton label='Add Fund' tab='AddFund' icon={iconMap['wallet']} onClick={() => { handleTabClick('AddFund'); handleAddfund(); handleSubTabClick('Add Fund') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Addfund ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Add Fund' onClick={() => { handleSubTabClick('Add Fund') }} />
                                {/* <SubTabButton label='Deposit History' onClick={() => handleSubTabClick('Deposit History')} /> */}
                            </div>
                            <TabButton label='Subscription' tab='Subscription' icon={iconMap['credit']} onClick={() => { handleTabClick('Subscription'); handleSubscribe(); handleSubTabClick('Subscribe') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Subscribe ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Subscribe' onClick={() => { handleSubTabClick('Subscribe') }} />
                                {/* <SubTabButton label='Subscribe History' onClick={() => handleSubTabClick('Subscribe History')} /> */}
                            </div>
                            <TabButton label='My Directives' tab='My Directives' icon={iconMap['users']} onClick={() => { handleTabClick('My Directives'); handleTeam(); handleSubTabClick('All Team') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Team ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='All Team' onClick={() => { handleSubTabClick('All Team') }} />
                                <SubTabButton label='Direct Team' onClick={() => handleSubTabClick('Direct Team')} />
                                <SubTabButton label='InDirect Team' onClick={() => handleSubTabClick('InDirect Team')} />

                                {/* <SubTabButton label='Active Team' onClick={() => { handleSubTabClick('Active Team') }} />
                                <SubTabButton label='Inactive Team' onClick={() => { handleSubTabClick('Inactive Team') }} /> */}
                                <SubTabButton label='Visual Structure' onClick={() => handleSubTabClick('Visual Structure')} />
                            </div>
                            <TabButton label='Reports' tab='Reports' icon={iconMap['file']} onClick={() => { handleTabClick('Reports'); handleReport(); handleSubTabClick('Direct Income') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${Report ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Direct Income' onClick={() => { handleSubTabClick('Direct Income') }} />
                                <SubTabButton label='Level Income' onClick={() => handleSubTabClick('Level Income')} />
                            </div>
                            <TabButton label='Withdraw' tab='Withdraw' icon={iconMap['withdraw']} onClick={() => { handleTabClick('Withdraw'); handleWithdraw(); handleSubTabClick('Withdraw Income') }} />
                            <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                ${withdraws ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                <SubTabButton label='Withdraw Income' onClick={() => { handleSubTabClick('Withdraw Income') }} />
                                <SubTabButton label='Withdraw Report' onClick={() => handleSubTabClick('Withdraw Report')} />
                            </div>
                            <TabButton label='Skilleareum.ai' tab='Skilleareum.ai' icon={iconMap['file']} onClick={() => handleTabClick('Skilleareum.ai')} />
                            <TabButton label='Profile' tab='Profile' icon={iconMap['id']} onClick={() => handleTabClick('Profile')} />
                            <button
                                className={`flex justify-center gap-3 w-[80%] md:w-[100%] lg:w-[90%] 2xl:w-[75%] mx-auto uppercase font-bold rounded-xl py-2.5 duration-500`}
                                onClick={Logout}>
                                <div className='relative w-[10%]'>
                                    <FontAwesomeIcon icon={iconMap['logout']} className='text-lg' />
                                </div>
                                <span className="w-[75%] md:w-[60%] text-start">Logout</span>
                            </button>


                        </nav>
                    </div>

                    <div className='block md:hidden'>
                        <button onClick={handleOpenNavbar} ref={buttonRef}>
                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7" />
                            </svg>
                        </button>
                        {isOpenNavbar && (
                            <nav className='w-[80%] z-10 py-5 absolute border rounded-3xl bg-[#872245] text-white grid content-around ' data-aos="fade-right" data-aos-duration="1000" ref={dropdownRef} >
                                <div>
                                    <div>
                                        <TabButton label='Dashboard' tab='Dashboard' icon={iconMap['cube']} onClick={() => handleTabClick('Dashboard')} />
                                        <TabButton label='Add Fund' tab='AddFund' icon={iconMap['wallet']} onClick={() => { handleTabClick('AddFund'); handleAddfund(); handleSubTabClick('AddFund') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Addfund ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Add Fund' onClick={() => { handleSubTabClick('AddFund') }} />
                                            {/* <SubTabButton label='Deposit History' onClick={() => handleSubTabClick('Deposit')} /> */}
                                        </div>
                                        <TabButton label='Subscription' tab='Subscription' icon={iconMap['credit']} onClick={() => { handleTabClick('Subscription'); handleSubscribe(); handleSubTabClick('Subscribe') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Subscribe ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Subscribe' onClick={() => { handleSubTabClick('Subscribe') }} />
                                            {/* <SubTabButton label='Subscribe History' onClick={() => handleSubTabClick('SubscribeHistory')} /> */}
                                        </div>
                                        <TabButton label='My Directives' tab='My Directives' icon={iconMap['users']} onClick={() => { handleTabClick('My Directives'); handleTeam(); handleSubTabClick('All Team') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Team ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='All Team' onClick={() => { handleSubTabClick('All Team') }} />
                                            <SubTabButton label='Direct Team' onClick={() => handleSubTabClick('Direct Team')} />
                                            <SubTabButton label='InDirect Team' onClick={() => handleSubTabClick('InDirect Team')} />

                                            {/* <SubTabButton label='Active Team' onClick={() => { handleSubTabClick('Active Team') }} />
                                            <SubTabButton label='Inactive Team' onClick={() => { handleSubTabClick('Inactive Team') }} /> */}
                                            <SubTabButton label='Visual Structure' onClick={() => handleSubTabClick('Visual Structure')} />
                                        </div>
                                        <TabButton label='Reports' tab='Reports' icon={iconMap['file']} onClick={() => { handleTabClick('Reports'); handleReport(); handleSubTabClick('Direct Income') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${Report ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Direct Income' onClick={() => { handleSubTabClick('Direct Income') }} />
                                            <SubTabButton label='Level Income' onClick={() => handleSubTabClick('Level Income')} />
                                        </div>
                                        <TabButton label='Withdraw' tab='Withdraw' icon={iconMap['withdraw']} onClick={() => { handleTabClick('Withdraw'); handleWithdraw(); handleSubTabClick('Withdraw Income') }} />
                                        <div className={`transition-all font-poppins bg-white divide-y text-black rounded-md w-[80%] md:w-[100%] lg:w-[80%] 2xl:w-[75%] mx-auto grid text-center duration-700 overflow-hidden px-2 
                                        ${withdraws ? 'max-h-[300px] py-2' : 'max-h-0'}`}>
                                            <SubTabButton label='Withdraw Income' onClick={() => { handleSubTabClick('Withdraw Income') }} />
                                            <SubTabButton label='Withdraw Report' onClick={() => handleSubTabClick('Withdraw Report')} />
                                        </div>
                                        <TabButton label='Skilleareum.ai' tab='Skilleareum.ai' icon={iconMap['file']} onClick={() => handleTabClick('Skilleareum.ai')} />
                                        <TabButton label='Profile' tab='Profile' icon={iconMap['id']} onClick={() => handleTabClick('Profile')} />
                                    </div>
                                    <div className='flex justify-center py-3 text-lg'><button className='font-Artemus font-bold uppercase' onClick={Logout}>Logout</button></div>
                                </div>
                            </nav>
                        )}
                    </div>


                    <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        <Analysis />
                    </div>
                    <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Add Fund' ? <Addfunds /> : activeSubTab === 'Deposit History' ? <Deposit /> : null}
                    </div>
                    <div id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Subscribe' ? <Subscription /> : activeSubTab === 'Subscribe History' ? <SubscriptionHistory /> : null}
                    </div>
                    <div
                        id='div4'
                        style={{ display: active === 'div4' ? 'block' : 'none' }}
                        className='md:w-[75%] lg:w-[80%] md:absolute right-0'
                    >
                        {(() => {
                            switch (activeSubTab) {
                                case 'All Team':
                                    return <AllTeam />;
                                case 'Direct Team':
                                    return <DirectTeam />;
                                case 'InDirect Team':
                                    return <InDirectTeam />;
                                case 'Active Team':
                                    return <ActiveTeam />;
                                case 'Inactive Team':
                                    return <InactiveTeam />;
                                case 'Visual Structure':
                                    return <VisualStructure />;
                                default:
                                    return null;
                            }
                        })()}
                    </div>
                    <div id='div5' style={{ display: active === 'div5' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Direct Income' ? <DirectIncome /> : activeSubTab === 'Level Income' ? <LevelIncome /> : null}
                    </div>
                    <div id='div6' style={{ display: active === 'div6' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        {activeSubTab === 'Withdraw Income' ? <WithdrawIncome /> : activeSubTab === 'Withdraw Report' ? <WithdrawReport /> : null}
                    </div>
                    <div id='div7' style={{ display: active === 'div7' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        <Skilleareumai />
                    </div>
                    <div id='div8' style={{ display: active === 'div8' ? 'block' : 'none' }} className='md:w-[75%] lg:w-[80%] md:absolute right-0'>
                        <AccountSettings />
                    </div>
                </div>
            </div>
        </div>
    );
}
