import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { setUser, SET_TOKEN } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { SERVER_URL } from "../../config";

Aos.init();

export default function Header() {
    const dispatch = useDispatch();
    const location = useLocation();

    const user = useSelector((state) => state.user);
    const email = user[0] ? user[0].email : '';

    const [isDropdown, setDropdown] = useState(false);
    const [isLogoutDropdown, setLogoutDropdown] = useState(false);
    const logoutDropdownRef = useRef(null);
    const memberLoginButtonRef = useRef(null);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);



    const Logout = () => {
        dispatch(setUser(""));
        window.location = '/';
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const handleDropdown = () => {
        setDropdown((prev) => !prev);
        setLogoutDropdown(false);
    };

    // const handleLogoutDropdown = () => {
    //     setLogoutDropdown((prev) => !prev);
    //     setDropdown(false);
    //     setLogoutDropdown(false);
    // };


    const handleLogoutDropdown = () => {
        setLogoutDropdown((prev) => !prev);
        setDropdown(false);
    };

    const handleClickOutside = (event) => {
        if (
            (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) ||
            (isLogoutDropdown && logoutDropdownRef.current && !logoutDropdownRef.current.contains(event.target) && memberLoginButtonRef.current && !memberLoginButtonRef.current.contains(event.target))
        ) {
            setDropdown(false);
            setLogoutDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdown, isLogoutDropdown]);

    const truncateEmail = (email) => {
        if (!email) return '';
        return email.length > 12 ? `${email.slice(0, 12)}...` : email;
    };

    const linkClasses = 'font-bold my-auto relative hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#E43875] to-[#F98736] transition-all ease-in-out before:transition-[width] before:ease-in-out before:duration-700 before:absolute before:bg-sky-400 before:origin-cente after:transition-[width] after:ease-in-out after:duration-700 after:absolute after:bg-sky-400 after:origin-center';
    const activeLinkClasses = 'text-transparent bg-clip-text bg-gradient-to-r from-[#E43875] to-[#F98736]';


    return (
        <div className=' font-poppins text-white border-b border-white/50 fixed left-0 right-0 backdrop-blur-sm z-50 bg-top bg-cover' style={{ backgroundImage: 'url("/assets/NewMBCbg.png")' }}>
            <div className='hidden lg:block'>
                <div className='flex justify-between py-3 px-5'>
                    <div className='w-[15%] flex justify-center'>
                        <a href='/'><img src='/assets/MBC_logo.png' className='w-[60px]'></img></a>
                    </div>
                    <div className='w-[60%] flex justify-around '>
                        <NavLink to='/' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            Home
                        </NavLink>
                        <NavLink to='/about' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            About us
                        </NavLink>
                        <NavLink to='/Exclusive' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            MBC Exclusive
                        </NavLink>
                        <NavLink to='/Membership' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            Membership
                        </NavLink>
                        <NavLink to='/FAQ' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                            FAQ
                        </NavLink>

                    </div>
                    <div className='w-[15%] flex items-center justify-center'>

                        {email ? (
                            <button className='bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full px-3 py-1 font-bold text-sm shadow-md' onClick={handleLogoutDropdown} ref={memberLoginButtonRef}>
                                {truncateEmail(email)}
                            </button>
                        ) : (
                            <NavLink to='/login'>
                                <button className='bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full px-3 py-1 font-bold text-sm shadow-md'>
                                    Member Login
                                </button>
                            </NavLink>
                        )}
                        {/* 
                        <NavLink to='https://user.mybusinessclub.com/Login.aspx'>
                            <button className='bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full px-3 py-1 font-bold text-sm shadow-md'>
                                Member Login
                            </button>
                        </NavLink>  */}
                    </div>
                </div>
                {isLogoutDropdown && (
                    <div className='absolute right-8 mt-2 z-60 top-[60px] w-[10rem]' data-aos='fade-up' ref={logoutDropdownRef}>
                        <div className='flex flex-col gap-1 border bg-[#571226] text-white text-center rounded-lg px-3 py-1'>
                            <NavLink to='/User/dashboard'>
                                Dashboard
                            </NavLink>
                            <button onClick={Logout}>Logout</button>
                        </div>
                    </div>
                )}
            </div>
            <div className='w-[100%] py-3 items-center px-5 lg:hidden block ease-in-out duration-300'>
                <div className='flex justify-between '>
                    <div>
                        <img src='/assets/MBC_logo.png' className='w-[60px]'></img>
                    </div>
                    <div className='my-auto'>
                        <button onClick={handleDropdown} ref={buttonRef}>
                            <FontAwesomeIcon icon={faBars} className='text-2xl' />
                        </button>
                    </div>

                    {isDropdown && (
                        <div className='dropdown-content  z-10 w-[12rem] absolute right-3 mt-2 top-20' data-aos='fade-up' ref={dropdownRef}>
                            <div className='flex flex-col gap-3 bg-[#571226] text-white text-center rounded-lg p-5'>
                                <NavLink to='/' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                                    Home
                                </NavLink>
                                <NavLink to='/about' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                                    About us
                                </NavLink>
                                <NavLink to='/Membership' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                                    Membership
                                </NavLink>
                                <NavLink to='/Exclusive' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                                    MBC Exclusive
                                </NavLink>
                                <NavLink to='/FAQ' className={({ isActive }) => (isActive ? `${linkClasses} ${activeLinkClasses}` : linkClasses)}>
                                    FAQ
                                </NavLink>
                                {/* <NavLink to='https://user.mybusinessclub.com/Login.aspx'><button className='bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full px-2 py-1 font-bold text-sm'>Member Login</button></NavLink> */}
                                {email ? (
                                    <button className='bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full px-3 py-1 font-bold text-sm shadow-md' onClick={handleLogoutDropdown} ref={memberLoginButtonRef}>
                                        {email.length > 8 ? email.slice(0,8) + '...' : email} 
                                    </button>
                                ) : (
                                    <NavLink to='/login'>
                                        <button className='bg-gradient-to-r from-[#E43875] to-[#F98736] rounded-full px-3 py-1 font-bold text-sm shadow-md'>
                                            Member Login
                                        </button>
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}