import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URL } from '../../../config';
import toast, { Toaster } from "react-hot-toast";

import { ClipLoader } from 'react-spinners';

export default function PaymentDetails() {

    const user = useSelector((state) => state.user);
    // const email = user[0]?.email;
    const email = "devtesting955@gmail.com";
    const CusID = user[0]?.CusID;

    // State for BEP-20 Address
    const [isEditingBEP, setIsEditingBEP] = useState(false);
    const [usdtBepAddress, setUsdtBepAddress] = useState('');
    const [newUsdtBepAddress, setNewUsdtBepAddress] = useState('');
    const [otpBep, setOtpBep] = useState('');

    // State for TRC-20 Address
    const [isEditingTRC, setIsEditingTRC] = useState(false);
    const [usdtTrcAddress, setUsdtTrcAddress] = useState('');
    const [newUsdtTrcAddress, setNewUsdtTrcAddress] = useState('');
    const [otpTrc, setOtpTrc] = useState();

    const [userData, setUserData] = useState({});
    const [transactions, setTransactions] = useState([])

    const [isLoading, setIsLoading] = useState(false); // Loader state

    const fetchDatas = async () => {
        setIsLoading(true); // Show loader
        try {
            const response = await axios.get(`${SERVER_URL}/api/getUsersDatas?CusID=${CusID}`);
            if (response.data && response.status === 200 && Array.isArray(response.data.data) && response.data.data.length > 0) {
                const data = response.data.data[0];
                setUserData(data);
                setUsdtBepAddress(data.withdraw_address || '');
                setUsdtTrcAddress(data.withdraw_wallet2 || '');
            } else {
                console.error("No user data found or data format is invalid");
            }
        } catch (err) {
            console.error("Error fetching user data:", err);
        } finally {
            setIsLoading(false); // Hide loader after data is fetched
        }
    };


    const fetchHistory = async () => {
        setIsLoading(true); // Show loader
        try {
            const response = await axios.get(`${SERVER_URL}/api/getAddressHistory?CusID=${CusID}`);
            if (response.data && response.status === 200) {
                const data = response.data.data || []; // Ensure data is an array or empty array
                setTransactions(data);
            } else {
                console.error("Failed to fetch history :", response.data.message);
            }
        } catch (err) {
            console.error("Error fetching history:", err);
        } finally {
            setIsLoading(false); // Hide loader after data is fetched
        }
    };


    useEffect(() => {
        if (CusID) {
            fetchDatas();
            fetchHistory();
        }
    }, [CusID]);

    // const handleEditClickBEP = () => {
    //     setIsEditingBEP(true);
    // };


    const handleEditClickBEP = async () => {
        setIsEditingBEP(true);
        toast.success('OTP sent to your email. Please check and verify.');

        try {
            const response = await axios.post(`${SERVER_URL}/api/generateOTPForAddressChange`, { email });

            if (response.data) {
                // Only set editing state if OTP was sent successfully
                // setIsEditingBEP(true);
                // toast.success('OTP sent to your email. Please check and verify.');
            } else {
                toast.error('Error sending OTP.');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error('Failed to send OTP.');
        }
    };



    const handleCancelClickBEP = () => {
        setIsEditingBEP(false);
        setNewUsdtBepAddress('');
        setOtpBep('');
    };

    // const handleUpdateClickBEP = async () => {
    //     try {
    //         let response = await axios.post(`${SERVER_URL}/api/AddressChange`, {
    //             CusID,
    //             old_address: usdtBepAddress,
    //             new_address: newUsdtBepAddress,
    //             otp: otpBep,
    //             type: 'Address 1'
    //         });
    //         if (response.data.status === true) {
    //             toast.success('BEP-20 address updated successfully!');
    //             fetchDatas();
    //             fetchHistory();
    //             setIsEditingBEP(false);
    //             setUsdtBepAddress(newUsdtBepAddress);
    //             setNewUsdtBepAddress('');
    //             setOtpBep('');
    //         } else {
    //             toast.error('Error while updating');
    //         }
    //     } catch (error) {
    //         console.error('Error updating BEP-20 address:', error);
    //         toast.error('Failed to update BEP-20 address.');
    //     }
    // };


    const handleUpdateClickBEP = async () => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/AddressChange`, {
                CusID,
                new_address: newUsdtBepAddress,
                otp: otpBep,
                type: "Bep-20",
                email
            });

            if (response.data.status === true) {
                toast.success('BEP-20 address updated successfully!');
                fetchDatas();  // Fetch updated data
                fetchHistory(); // Fetch history if applicable
                setIsEditingBEP(false); // Close editing mode
                setUsdtBepAddress(newUsdtBepAddress); // Update the address in state
                setNewUsdtBepAddress(''); // Reset the new address input
                setOtpBep(''); // Reset the OTP input
            } else {
                toast.error(response.data.message || 'Error while updating');
            }
        } catch (error) {
            console.error('Error updating BEP-20 address:', error);
            toast.error('Failed to update BEP-20 address.');
        }
    };




    // TRC-20 Edit Handlers
    const handleEditClickTRC = () => {
        setIsEditingTRC(true);
    };

    const handleCancelClickTRC = () => {
        setIsEditingTRC(false);
        setNewUsdtTrcAddress('');
        setOtpTrc('12345');
    };

    const handleUpdateClickTRC = async () => {
        try {
            let response = await axios.post(`${SERVER_URL}/api/AddressChange`, {
                CusID,
                old_address: usdtTrcAddress,
                new_address: newUsdtTrcAddress,
                otp: otpTrc,
                type: 'Address 2'
            });
            if (response.data.status === true) {
                toast.success('TRC-20 address updated successfully!');
                fetchDatas();
                fetchHistory();
                setIsEditingTRC(false);
                setUsdtTrcAddress(newUsdtTrcAddress);
                setNewUsdtTrcAddress('');
                setOtpTrc('12345');
            } else {
                toast.error('Error while updating');
            }

        } catch (error) {
            console.error('Error updating TRC-20 address:', error);
            toast.error('Failed to update TRC-20 address.');
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = Array.isArray(transactions)
        ? transactions.slice(indexOfFirstEntity, indexOfLastEntity)
        : [];
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(transactions.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(transactions.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        const options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-7 bg-white/10 rounded-xl shadow-md mt-5'>
                <h1 className='font-bold text-xl font-Artemus uppercase'>Withdrawal Address</h1>
                <div className='flex flex-col md:flex-row gap-5 mt-5'>
                    {/* USDT BEP-20 Address Section */}
                    <div className='md:w-[50%]'>
                        <div className='p-5 border border-dashed rounded-xl'>
                            <div className='flex items-center justify-between'>
                                <p className='text-lg'>USDT BEP-20 Address</p>
                                {!isEditingBEP && (
                                    <button
                                        className='bg-white text-black font-semibold text-sm rounded-md px-1 py-[2px]'
                                        onClick={handleEditClickBEP}
                                    >
                                        Edit with OTP
                                    </button>
                                )}
                            </div>

                            {isEditingBEP ? (
                                <div className='mt-3'>
                                    {/* BEP-20 Address Input */}
                                    <input
                                        type='text'
                                        className='px-5 py-3 border bg-white/10 outline-none font-semibold rounded-md mt-2 w-full placeholder:text-white'
                                        placeholder='Enter new USDT BEP-20 address'
                                        value={newUsdtBepAddress}
                                        onChange={(e) => setNewUsdtBepAddress(e.target.value)}
                                    />
                                    {/* OTP Input */}
                                    <input
                                        type='text'
                                        className='px-5 py-3 border bg-white/10 outline-none font-semibold rounded-md mt-2 w-full placeholder:text-white'
                                        placeholder='Enter OTP'
                                        value={otpBep}
                                        onChange={(e) => setOtpBep(e.target.value)}
                                    />
                                    <div className='flex justify-end mt-2'>
                                        <button
                                            className='text-xs bg-[#dd0000] font-medium px-2 py-1 rounded-md mr-2'
                                            onClick={handleCancelClickBEP}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className='text-xs bg-[#00cc00] font-medium px-2 py-1 rounded-md'
                                            onClick={handleUpdateClickBEP}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className='px-5 py-3 border hover:border-sky-300 duration-500 bg-white/10 outline-none font-semibold rounded-md mt-5 w-full cursor-not-allowed'>
                                        {usdtBepAddress}
                                    </div>
                                    <div className='flex justify-end mt-2'>
                                        <button
                                            className='text-xs bg-[#dd0000] font-medium px-2 py-1 rounded-md'
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* USDT TRC-20 Address Section */}
                    {/* <div className='md:w-[50%]'>
                        <div className='p-5 border border-dashed rounded-xl'>
                            <div className='flex items-center justify-between'>
                                <p className='text-lg'>USDT TRC-20 Address</p>
                                {!isEditingTRC && (
                                    <button
                                        className='bg-white text-black font-semibold text-sm rounded-md px-1 py-[2px]'
                                        onClick={handleEditClickTRC}
                                    >
                                        Edit with OTP
                                    </button>
                                )}
                            </div>

                            {isEditingTRC ? (
                                <div className='mt-3'>
                                   
                                    <input
                                        type='text'
                                        className='px-5 py-3 border bg-white/10 outline-none font-semibold rounded-md mt-2 w-full placeholder:text-white'
                                        placeholder='Enter new USDT TRC-20 address'
                                        value={newUsdtTrcAddress}
                                        onChange={(e) => setNewUsdtTrcAddress(e.target.value)}
                                    />
                               
                                    <input
                                        type='text'
                                        className='px-5 py-3 border bg-white/10 outline-none font-semibold rounded-md mt-2 w-full placeholder:text-white'
                                        placeholder='Enter OTP'
                                        value={otpTrc}
                                        onChange={(e) => setOtpTrc(e.target.value)}
                                    />
                                    <div className='flex justify-end mt-2'>
                                        <button
                                            className='text-xs bg-[#dd0000] font-medium px-2 py-1 rounded-md mr-2'
                                            onClick={handleCancelClickTRC}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className='text-xs bg-[#00cc00] font-medium px-2 py-1 rounded-md'
                                            onClick={handleUpdateClickTRC}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className='px-5 py-3 border hover:border-sky-300 duration-500 bg-white/10 outline-none font-semibold rounded-md mt-5 w-full cursor-not-allowed'>
                                        {usdtTrcAddress}
                                    </div>
                                    <div className='flex justify-end mt-2'>
                                        <button
                                            className='text-xs bg-[#dd0000] font-medium px-2 py-1 rounded-md'
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='p-7 bg-white/10 rounded-xl shadow-md mt-5'>
                <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between pb-5'>
                    <div className='md:w-[40%]'>
                        <p className='my-auto font-bold text-xl font-Artemus uppercase'>Address History</p>
                    </div>
                    <div className='flex items-center'>
                        <p className='text-end text-sm px-3 my-auto'>Show no of entity
                            <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                <option className='text-black' value="5">5</option>
                                <option className='text-black' value="10">10</option>
                                <option className='text-black' value="50">50</option>
                            </select>
                        </p>
                    </div>
                </div>


                {isLoading ? (
                    <div className='flex justify-center items-center py-10'>
                        <ClipLoader color="#00cc00" loading={isLoading} size={50} />
                    </div>
                ) : (
                    <div className='mb-5 border rounded-xl overflow-hidden overflow-x-auto'>
                        <table className='w-full text-center'>
                            <thead className='font-Artemus uppercase font-bold text-lg border-b py-3'>
                                <tr>
                                    <th className='py-3 px-5 md:px-2'>CustID</th>
                                    <th className='px-5 md:px-2'>Type</th>
                                    <th className='px-5 md:px-2 whitespace-nowrap'>Old Address</th>
                                    <th className='px-5 md:px-2 whitespace-nowrap'>New Address</th>
                                    <th className='px-5 md:px-2 whitespace-nowrap'>Date / Time</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr key={index} className='h-16 text-sm md:text-base text-center rounded-md font-bold'>
                                            <td className='px-5 md:px-0 font-Artemus'>{entity.CusID || ''}</td>
                                            <td className='px-5 md:px-0'>{entity.type || ''}</td>
                                            <td className='px-5 md:px-0'>
                                                {entity.old_address && entity.old_address.length > 10 ? `${entity.old_address.slice(0, 10)}...` : entity.old_address || ''}
                                            </td>
                                            <td className='px-5 md:px-0'>
                                                {entity.new_address.length && entity.new_address.length > 10 ? `${entity.new_address.slice(0, 10)}...` : entity.new_address || ''}
                                            </td>
                                            <td className='px-5 md:px-0'>{formatDate(entity.created_at) || ''}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className='py-5 text-center font-semibold text-lg'>No data found</td>
                                    </tr>
                                )}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                )}






                <div className='mt-2 flex justify-between text-xs px-3'>
                    <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                    <div className='my-auto flex justify-center items-center gap-1'>
                        <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                        {pageNumbers.map((pageNumber) => (
                            <button
                                key={pageNumber}
                                className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                onClick={() => handlePageClick(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                    </div>
                    <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                </div>
            </div>
        </div>
    );
}