import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function Closedticket() {
    const tableEntries = [
        {
            id: 1,
            ref: 'Process',
            Sno: '3',
            custID: '468831',
            email: 'Lifestylewithderek@gmail.com',
            custusername: 'Dezzie333',
            receiver: '1',
            Txno: '5323254',
            Message: '74920 Userid : 468831<br> Deposit Date :5/8/2024 12:00:00 AM<br> Amount USD :31.66<br> Amount BTC :31.66<br> Remark_all :paid slightly over cause of fees ( Txn Key : NXTMF0QlpA1i8x4QflvkfWgn )',
            Timestamp: '2024-09-16 10:00:00',
            status: '0',
            statusreport: 'Closed'
        }
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5);

    // Determine the current entries to display
    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = tableEntries.slice(indexOfFirstEntity, indexOfLastEntity);
    const emptyRowCount = entitiesPerPage - currentEntities.length;

    // Handle previous and next page clicks
    const handlePrevClick = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleNextClick = () => {
        setCurrentPage(prevPage => {
            const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
            return Math.min(prevPage + 1, totalPages);
        });
    };

    // Handle page number click
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Handle dropdown change for entities per page
    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };


    // Calculate pagination details
    const totalPages = Math.ceil(tableEntries.length / entitiesPerPage);
    const maxPageNumbersToShow = 5;
    const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);
    const pageNumbers = [];

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div data-aos="fadeIn" data-aos-duration='2000'>
            <div className='p-3 md:p-7 mt-5 font-poppins'>
                <h1 className='text-2xl font-bold uppercase font-Artemus'>Close Ticket
                </h1>
                <div className=' rounded-lg mt-2 border '>
                    <div className='px-5 py-3 border-b  font-bold'>
                        <p>Filter Your Search</p>
                    </div>
                    <div className='p-3 grid md:grid-cols-3 lg:grid-cols-5 gap-3 lg:gap-10'>
                        <div className=' text-sm font-bold m-auto'>
                            Total Record : 4
                        </div>
                        <div></div>
                        <div></div>
                        <div className='flex md:grid gap-1 m-auto items-center justify-center'>
                            <p className='text-sm font-bold text-nowrap w-[50%]'>Records Per Page :</p>
                            <div className='w-[25%] md:w-[100%]'>
                                <input className='w-[100%] border bg-transparent rounded-md py-1'></input>
                            </div>
                        </div>
                        <div className=' text-sm font-bold m-auto'>
                            Page of 1 of 0
                        </div>
                    </div>
                </div>

                <div className=' rounded-lg mt-5 border pb-3'>
                    <div className='flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center px-5 py-3 border-b'>
                        <div>
                        <p className='font-bold'>Showing All Ticket</p>
                        </div>
                        <div className='flex items-center'>
                            <p className='text-end text-sm px-3 my-auto'>Show no of entity
                                <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                    <option className='text-black' value="5">5</option>
                                    <option className='text-black' value="7">7</option>
                                    <option className='text-black' value="10">10</option>
                                </select>
                            </p>
                        </div>
                    </div>
                    <div className='mb-5 overflow-hidden hover:overflow-x-auto'>
                        <table className='w-full text-center text-[13px]'>
                            <thead className=' uppercase font-bold border-b py-3 text-nowrap'>
                                <tr>
                                    <th className='py-3 px-2'>#</th>
                                    <th className='px-2'>Ref</th>
                                    <th className='px-2'>#</th>
                                    <th className='px-2'>CustID</th>
                                    <th className='px-2'>EmailID</th>
                                    <th className='px-2'>Cust Username</th>
                                    <th className='px-2'>Receiver</th>
                                    <th className='px-2'>TxNo</th>
                                    <th className='px-2'>Message</th>
                                    <th className='px-2'>Time Stamp</th>
                                    <th className='px-2'>Status</th>
                                    <th className='px-2'>Status Report</th>
                                </tr>
                            </thead>
                            <tbody className='divide-y'>
                                {currentEntities.map((entity, index) => (
                                    <tr key={index} className='h-16 text-center rounded-md font-bold'>
                                        <td className='px-5 md:px-2'>{entity.id}</td>
                                        <td className='px-5 md:px-2'>{entity.ref}</td>
                                        <td className='px-5 md:px-2'>{entity.Sno}</td>
                                        <td className='px-5 md:px-2'>{entity.custID}</td>
                                        <td className='px-5 md:px-2'>{entity.email}</td>
                                        <td className='px-5 md:px-2'>{entity.custusername}</td>
                                        <td className='px-5 md:px-2'>{entity.receiver}</td>
                                        <td className='px-5 md:px-2'>{entity.Txno}</td>
                                        <td className='px-5 md:px-2'>{entity.Message}</td>
                                        <td className='px-5 md:px-2'>{entity.Timestamp}</td>
                                        <td className='px-5 md:px-2'>{entity.status}</td>
                                        <td className='px-5 md:px-2'>{entity.statusreport}</td>
                                    </tr>
                                ))}
                                {/* Add empty rows if needed */}
                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                    <tr key={`empty-${index}`} className='h-16'>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-2 flex justify-between text-xs px-3'>
                        <button className='bg-white text-black font-bold rounded-md px-5 py-1' onClick={handlePrevClick} disabled={currentPage === 1}>Prev</button>
                        <div className='my-auto flex justify-center items-center gap-1'>
                            <FontAwesomeIcon icon={faChevronLeft} className='cursor-pointer' onClick={handlePrevClick} />
                            {pageNumbers.map((pageNumber) => (
                                <button
                                    key={pageNumber}
                                    className={`px-2 py-1 ${pageNumber === currentPage ? 'font-bold' : ''}`}
                                    onClick={() => handlePageClick(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            ))}
                            <FontAwesomeIcon icon={faChevronRight} className='cursor-pointer' onClick={handleNextClick} />
                        </div>
                        <button className='bg-white text-black font-bold  rounded-md px-5 py-1' onClick={handleNextClick} disabled={currentEntities.length < entitiesPerPage}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
